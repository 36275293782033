import { OwlOptions } from 'ngx-owl-carousel-o';
import {Component} from '@angular/core';

@Component({
  selector: 'carousel-o',
  templateUrl: 'carousel-holder.component.html'
})
export class CarouselHolderComponent {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  planes = [
    {
      id: 1,
      costo: 100,
      tipo: 'Mensual',
      renta: 50,
      ahorro: false,
      valor_ahorro: 0,
      porcentaje_ahorro: 0,
    },
    {
      id: 2,
      costo: 100,
      tipo: 'Semestral',
      renta: 250,
      ahorro: true,
      valor_ahorro: 50,
      porcentaje_ahorro: 15,
    },
    {
      id: 3,
      costo: 100,
      tipo: 'Anual',
      renta: 480,
      ahorro: true,
      valor_ahorro: 120,
      porcentaje_ahorro: 20,
    }
  ];
}
