<div class="col-md-10 mx-auto">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb mb-0 small">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/cotizar">Cotizar</a></li>
      <li class="breadcrumb-item active">Compañía Logística</li>
    </ol>
  </nav>
</div>

<div class="bg-light-blue">
  <div class="bannerResultadoCotizador">
    <div class="col-sm-12 mx-auto d-flex justify-content-center h-100">
      <div class="text-white col-sm-12 col-md-8 col-lg-7 d-flex justify-content-center align-items-center">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/formulario-GIF-Logistico.gif?alt=media&token=10c3c63c-83fb-4167-99f1-c6e87b770a96"
          alt=""
          class="ml-4 mr-1">
        <div class="text-left desk-view">
          <p *ngIf="cotizacion" class="h4  mb-2 font-weight-700">Hola {{cotizacion.datos.nombre | titlecase}},</p>
          <p class="h5 w-75 mb-0">Te mostramos el detalle de tu cotización. Además, te hemos enviado un correo
            electrónico con la cotización solicitada.</p>
        </div>
        <div class="text-left mobile-view">
          <p *ngIf="cotizacion" class="h5  mb-2 font-weight-700">Hola {{cotizacion.datos.nombre | titlecase}},</p>
          <p class="h6 w-75 mb-0">Te mostramos el detalle de tu cotización. Además, te hemos enviado un correo
            electrónico con la cotización solicitada.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 d-flex flex-row flex-wrap justify-content-center align-items-start m-0 mt-3 pt-5">
    <div class="d-none d-sm-none d-md-flex  justify-content-center px-3 align-items-center flex-column"
         *ngFor="let el of planes">
      <button class="btn h4 btn-secondary radius-sm medium w-75 font-weight-800 px-3">Plan {{ el.tipo }}</button>
      <div class=" px-lg-1  d-flex justify-content-center px-md-0 py-1" style="max-width: 360px!important"
           *ngIf="cotizacion">
        <div class="bg-white columnResultadoCotizador columnResultadoCotizador--large border-secondary border p-3">
          <div class="d-flex justify-content-center">
            <div class="text-center d-flex flex-column flex-wrap justify-content-center align-items-center">
              <div class="pb-2 pt-1">
                <img class="mx-1"
                     src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/gps_vector.svg?alt=media&token=97aff883-1996-46b7-b234-472748560be3"
                     alt="">
              </div>
              <p class="h4 text-secondary   mb-1">EQUIPO GPS AVL </p>
              <p class="h3  text-secondary mb-2 font-weight-800">US$ {{ el.costo }} </p>
              <p class="mb-0 font-weight-500">Incluye instalación.</p>
              <p class="mb-1 font-weight-500">Costo expresado en dólares <br>americanos.</p>
              <hr class=" w-100 "/>
              <div class="pb-2 pt-1">
                <img class="mx-1"
                     [src]="el.urlimg"
                     alt="">
              </div>
              <p class="h4 text-secondary  mb-1">Plan de Servicio </p>
              <p class="h4  text-secondary mb-2 ">{{ el.tipo }} </p>
              <p class="h3  text-secondary mb-2 font-weight-800">S/ {{ el.renta }} </p>
              <p class="mb-0 font-weight-500">Precio variable dependiendo</p>
              <p class=" font-weight-500" [class]="{ 'mb-5': !el.ahorro }">de la cantidad de vehículos</p>
              <div *ngIf="el.ahorro">
                <button>AHORRAS {{ el.porcentaje_ahorro }}%</button>
                <p class="text-success"><small> Ahorras S/{{ el.valor_ahorro }} vs el plan mensual</small></p>
              </div>
              <div class="text-center mt-1 mb-5" [ngClass]="{'mt-4 pt-3': !el.ahorro }">
                <a class="btn btn-primary font-weight-600 btn-md btn-block py-2 px-3"
                   href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                   target="blank">Contactar a un asesor</a>
              </div>
              <p class="small text-muted mb-0">* Los precios <strong class="str">No</strong> incluyen I.G.V.</p>
              <p class="small text-muted">* Los precios expresados son por un (1) vehículo.</p>
            </div>
          </div>
        </div>


      </div>
      <!--     desktop view -->
    </div>
    <owl-carousel-o #owlCar class=" d-md-none d-lg-none d-xl-none d-flex w-100 " [options]="customOptions">

      <ng-container>
        <ng-template carouselSlide *ngFor="let el of planes" [width]="300" class="mnw-300  w-400">
          <div>
            <button class=" imgButtonCarousel imgButtonCarousel--left ">
              <img *ngIf="el.iz" (click)="owlCar.prev()" title="desliza a la iz" class=""
                   src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/arrowleft.svg?alt=media&token=f95e24f7-a202-4764-b82b-1aaca0a0abc2"
                   alt="<">
            </button>
            <button class=" imgButtonCarousel imgButtonCarousel--right">
              <img *ngIf="el.der" (click)="owlCar.next()" title="desliza a la der" class=" "
                   src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/arrowright.svg?alt=media&token=9383bd56-6efb-4957-a8f2-8bd60eafe93e"
                   alt=">">
            </button>
          </div>
          <div [id]="el.id" class="d-flex justify-content-center mx-1 align-items-center flex-column">
            <button class="btn  btn-secondary radius-sm medium w-75 font-weight-800 px-3">Plan {{ el.tipo }}</button>
            <div class=" px-lg-4  d-flex justify-content-center px-md-0 py-3 py-md-5" *ngIf="cotizacion">
              <div
                class=" bg-white columnResultadoCotizador  columnResultadoCotizador--large border-secondary border p-3">
                <div class="d-flex justify-content-center">
                  <div class="text-center d-flex flex-column flex-wrap justify-content-center align-items-center">
                    <div class="pb-2 pt-1">
                      <img class="mx-1"
                           src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/gps_vector.svg?alt=media&token=97aff883-1996-46b7-b234-472748560be3"
                           alt="">
                    </div>
                    <p class="h4 text-secondary  font-weight-700 mb-1">EQUIPO GPS AVL </p>
                    <p class="h3  text-secondary mb-2 font-weight-800">US$ {{ el.costo }} </p>
                    <p class="mb-0 font-weight-500">Incluye instalación.</p>
                    <p class="mb-1 font-weight-500">Costo expresado en dólares <br>americanos.</p>
                    <hr class=" w-100 "/>
                    <div class="pb-2 pt-1">
                      <img class="mx-1"
                           [src]="el.urlimg"
                           alt="">
                    </div>
                    <p class="h4 text-secondary  font-weight-700 mb-1">Plan de Servicio </p>
                    <p class="h4  text-secondary mb-2 font-weight-800">{{ el.tipo }} </p>
                    <p class="h3  text-secondary mb-2 font-weight-800">S/ {{ el.renta }} </p>
                    <p class="mb-0 font-weight-500">Precio variable dependiendo</p>
                    <p class="mb-5 font-weight-500">de la cantidad de vehículos</p>
                    <div *ngIf="el.ahorro">
                      <button>AHORRAS {{ el.porcentaje_ahorro }}%</button>
                      <p class="text-success"><small> Ahorras S/{{ el.valor_ahorro }} vs el plan mensual</small></p>
                    </div>
                    <div class="text-center mt-1 mb-5" [ngClass]="{'mt-4 pt-3': !el.ahorro }">
                      <a class="btn btn-primary font-weight-800 btn-md btn-block px-3"
                         href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                         target="blank">Contactar a un asesor</a>
                    </div>
                    <p class="small text-muted mb-0">* Los precios <strong class="str">No</strong> incluyen I.G.V.</p>
                    <p class="small text-muted">* Los precios expresados son por un (1) vehículo.</p>
                  </div>
                </div>
              </div>


            </div>
            <!--     desktop view -->
          </div>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>
  <div class="col-12 bg-white pb-5 pt-2">
    <div class="text-center mt-3 mt-md-4">
      <p class="h5 font-weight-800 py-4">Gracias a la confianza de nuestros clientes hemos desarrollado productos <br>para
        diferentes sectores de negocios.</p>
      <div class="d-flex flex-row flex-wrap  justify-content-around align-items-center">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/sodimac.png?alt=media&token=72c07260-995a-47b6-ad20-7505c8c84b14"
             alt="SODIMAC">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Arca%20Continental%20Lindley.png?alt=media&token=604afa62-120c-4ec3-b97a-1eea10066328"
             alt="ARCACONTINENTAL">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Coca%20Cola.png?alt=media&token=fcff9d16-b40d-4c79-b192-2842808a2cdb"
             alt="COCACOLA">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Inca%20Kola.png?alt=media&token=5685d701-13c2-4829-a1e0-a510678de781"
             alt="INCAKOLA">
      </div>
      <div class="d-flex flex-row  flex-wrap justify-content-around align-items-center">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/saga%20falabella.png?alt=media&token=fa13ea11-e7cf-48b6-9c5e-2a86aafd8694"
             alt="SAGAFALABELLA">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Kimberly%20Clark.png?alt=media&token=d4e9d66f-dd41-42bd-be9e-0a4da541b0a5"
             alt="KIMBERLY">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Rico%20Pollo%2050%20a%C3%B1os.png?alt=media&token=3504b277-c968-47d9-83ea-4880b0a563fa"
             alt="RICOPOLLO">
        <img class=" px-lg-3 px-md-4 px-sm-6 my-3 mw-250"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Orbes%20Agr%C3%ADcola.png?alt=media&token=97fd7019-3ffa-4013-9d74-69c34e9c0db9"
             alt="ORBES">
      </div>
    </div>
  </div>
  <div class="col-12 bannerGift bg-primary">
    <p class="h2 font-weight-800 text-white"> Tecnología a la medida de tu negocio</p>
    <div class="orbitec-gift">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Gif-Logistico.gif?alt=media&token=5e79d344-71bd-40a5-ac1f-a4513b3d97ed"
        alt="">
    </div>
  </div>
  <div class="col-12 bg-light-blue py-5">
    <div class="text-center mt-3 mt-md-4">
      <img width="150" height="85"
           src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/WhatsApp-logo%201.png?alt=media&token=37e371cb-c472-49d2-a76b-456aa8a29946"
           alt="WhatsApp">
      <p class="h5 font-weight-800 py-4">¡Empieza ahora y toma control de tu vehículo!</p>
      <a class="btn btn-primary  font-weight-800 px-4"
         href="{{ urlContacto(cotizacion.datos.nombre, 'anual para empresa', cotizacion.datos.tipoVehiculo, idCotizacion) }}"
         target="blank">Contactar a un asesor</a>
    </div>
  </div>
  <div class="col-12 bg-white py-5">
    <div class="text-center mt-3 mt-md-4">
      <p class="h4 font-weight-800 py-4">Acreditado por las autoridades</p>
      <div class="d-flex flex-md-row flex-sm-column justify-content-around flex-wrap">
        <img class="mx-3 my-3"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_ministerio%202.svg?alt=media&token=74f890e5-e8ca-41b8-a6e4-d601ff9030e7"
             alt="">
        <img class="mx-3 my-3"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_sutran%202.svg?alt=media&token=fcdd9e42-910e-4bcc-9395-4b02d601960b"
             alt="">
        <img class="mx-3 my-3"
             src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_osinergmin%201.svg?alt=media&token=0093ac0e-3878-4883-82a6-70e2794eaa7d"
             alt="">
      </div>
    </div>
  </div>
</div>
