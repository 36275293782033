<div class="col-md-11 mx-auto py-3 py-md-4">


  <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
    <p class="h3 mb-0">Cotizaciones Vehículo Particular</p>
    <!-- <div class="">
        <mat-form-field appearance="outline" class="myPaddingField">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="value">
            <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
            <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilters()">
          <mat-icon>close</mat-icon>
        </button>
        </mat-form-field>
    </div> -->
  </div>


  <div class="table-responsive">

    <table mat-table [dataSource]="cotizacionesEmpresaData" matSort class="example-table">

      <ng-container matColumnDef="datos.fechaCreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let item"> {{ item.datos.fechaCreacion.toDate() | date: 'dd/MM/yyyy hh:mm a' }} </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let item"> {{ item.id }} </td>
      </ng-container>

      <ng-container matColumnDef="datos.nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let item"> {{ item.datos.nombre }} </td>
      </ng-container>

      <ng-container matColumnDef="datos.apellidos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
        <td mat-cell *matCellDef="let item">{{item.datos.apellidos}}</td>
      </ng-container>

      <ng-container matColumnDef="datos.email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let item"> {{ item.datos.email }} </td>
      </ng-container>

      <ng-container matColumnDef="datos.celular">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Celular</th>
        <td mat-cell *matCellDef="let item"> {{ item.datos.celular }} </td>
      </ng-container>

      <ng-container matColumnDef="datos.tipoVehiculo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo vehículo</th>
        <td mat-cell
            *matCellDef="let item">{{(item.datos.tipoVehiculo !== null && item.datos.tipoVehiculo !== undefined) ? item.datos.tipoVehiculo : item.plan.tipoVehiculo }}</td>
      </ng-container>


      <ng-container matColumnDef="datos.whatsApp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WhatsApp</th>
        <td mat-cell *matCellDef="let item">
          <div *ngIf="item.datos.whatsApp">Si</div>
          <div *ngIf="!item.datos.whatsApp">No</div>
        </td>
      </ng-container>


      <ng-container matColumnDef="cotizacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cotización Url</th>
        <td mat-cell *matCellDef="let item">
          <a class="btn btn-link btn-sm" [routerLink]="'/cotizar/personaNatural/' + item.id + '/gracias'"
             target="blank">ver cotización</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef class=""></th>
        <td mat-cell *matCellDef="let item" class="text-right">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openModalDelete(item)">
              <mat-icon class="material-icons-outlined text-danger">delete</mat-icon>
              <span class="text-danger">Eliminar cotización</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
  </mat-paginator>


</div>
