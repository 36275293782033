
  <div class="col-sm-12 bg-white">
      <div class="col-md-10 mx-auto">
          <nav aria-label="breadcrumb mb-0">
              <ol class="breadcrumb mb-0 small">
                  <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
                  <li class="breadcrumb-item"><a routerLink="/cotizar">Cotizar</a></li>
                  <li class="breadcrumb-item active">Compañía Logística</li>
              </ol>
          </nav>
      </div>
  </div>
<div class="row m-0 wrapperCompany" >
    <div class="rightCompania p-0 col-sm-12 col-md-6 col-lg-4">
        <div class="textPersonalNatural">
            <p class="h2 font-weight-800 text-white">Cotiza online en menos de un minuto</p>
            <p class="h5 font-weight-500 text-white">Conoce el precio de tu plan y empieza a tener el control de tu vehículo.</p>
        </div>
    </div>
  <div class="py-3 py-md-4 pd-sm-2 col-sm-12 col-md-6 col-lg-4">
    <p class="h6 font-weight-600 text-center mobile-view text-secondary">Ingresa tus datos y conoce el <br/>   precio de tu plan al instante</p>
    <p class="h5 font-weight-600 text-center desk-view text-secondary">Ingresa tus datos y conoce el <br/>   precio de tu plan al instante</p>
                <form [formGroup]="formDatos" (ngSubmit)="onSubmit()" novalidate
                    class="row d-flex justify-content-center mt-3">
                    <mat-form-field appearance="outline" class="col-md-8 col-sm-10">
                        <mat-label>Nombre y Apellido</mat-label>
                        <input matInput formControlName="nombre">
                        <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-8 col-sm-10">
                        <mat-label>RUC</mat-label>
                        <input matInput formControlName="ruc" maxlength="11">
                        <mat-error *ngIf="errorRuc()">{{errorRuc()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-8 col-sm-10">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-8 col-sm-10">
                        <mat-label>Celular</mat-label>
                        <input matInput formControlName="celular" maxlength="9" placeholder="000000000">
                        <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-8 col-sm-10">
                        <mat-label>¿Qué tipo de vehículo tienes?</mat-label>
                        <mat-select formControlName="tipoVehiculo" multiple>
                            <mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo">{{vehiculo}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="errorTipoVehiculo()">{{errorTipoVehiculo()}}</mat-error>
                    </mat-form-field>
                    <div class="col-md-8 col-sm-10">
                        <mat-checkbox formControlName="acepta" class="small">
                            He leído y acepto la <button type="button" class="myButtonPrivacidad text-primary" (click)="openModalTerminos()"><u>Política de privacidad.</u></button>
                        </mat-checkbox>
                        <mat-error class="small" *ngIf="errorAcepta()">{{errorAcepta()}}</mat-error>
                    </div>
                    <div class="col-12 text-center mt-3 mt-md-1">
                        <button  class="btn btn-primary px-3 font-weight-800" >CONOCE EL PRECIO</button>
                    </div>
                </form>
    </div>
</div>
