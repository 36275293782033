<!-- <div class="bg-light">
    <div class="col-md-11 mx-auto">
        <div class="py-3 py-md-3 d-flex justify-content-between">
            <a class="h1 mb-0 text-decoration-none text-dark" routerLink="/admin">Admin</a>
            <div>
                <button class="btn btn-outline-primary px-3" (click)="logout()">Cerrar sesión</button>
            </div>

        </div>
    </div>
</div> -->

<div class="bg-light">
    <div class="col-md-11 mx-auto">
        <div class="py-3 py-md-3 d-flex justify-content-between align-items-center">
            <a class="h4 mb-0 text-decoration-none text-dark" routerLink="/admin">Admin Orbitec</a>
            <div class="d-flex align-items-center" *ngIf="userId">
                <div class="text-right mr-2">
                    <p class="mb-0 d-none d-md-block">{{email}}</p>
                </div>
                <!-- <img class="rounded-circle border mr-2" [src]="photoURL" width="60px"> -->
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                <mat-menu #menu="matMenu">
                    <div class="d-block d-md-none">
                        <button mat-menu-item>{{email}}</button>
                        <mat-divider></mat-divider>
                    </div>
                    <button mat-menu-item (click)="logout()">
                        <span>Cerrar sesión</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>