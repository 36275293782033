<section class="orbitec-navidad">
  <div class="container-fluid text-center pt-4 pl-4 pr-4">
    <img src="../../assets/images/navidad_guirnalda.svg" alt="">
    <h3>
      ¡Feliz Navidad y próspero Año Nuevo!
    </h3>
    <div class="textcontainer flex justify-center items-center">
      <p class="pb-3">
        Queremos agradecerte por confiar en nosotros y permitir que te acompañemos en este año de grandes retos, en el que
        nos tocó enfrentar una situación nunca antes vista.
      </p>
      <p class="pb-3">
        Esperamos que te encuentres muy bien y deseamos que tu hogar se ilumine de paz y amor en unión de tu familia,
        porque esa unión es la fuerza que nos permite salir adelante y alcanzar nuestras metas.
      </p>
      <p class="">
        Son los más sinceros deseos de tus amigos de Orbitec.
      </p>
    </div>

  </div>
  <section  class="orbitec-video">
    <video class="desktop" width="675" height="571"  autoplay controls>
      <source src="../../assets/images/Orbitec-video-saludo.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <video class="mobile" width="375" height="271"  autoplay controls>
      <source src="../../assets/images/Orbitec-video-saludo.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </section>

</section>
