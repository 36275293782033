import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  industrias;

  constructor() { }

  ngOnInit(): void {
    this.industrias = [

      {
        image: 'assets/images/industriaTransporte.svg',
        description: 'Transporte'
      },

      {
        image: 'assets/images/industriaConstruccion.svg',
        description: 'Construcción'
      },

      {
        image: 'assets/images/industriaLogistic.svg',
        description: 'Logística'
      },

      {
        image: 'assets/images/industriaEquipoPesado.svg',
        description: 'Equipos pesados'
      },

      {
        image: 'assets/images/industriaDistribucion.svg',
        description: 'Distribución'
      },

      {
        image: 'assets/images/industriaSeguridad.svg',
        description: 'Seguridad'
      },
    ];
  }

}
