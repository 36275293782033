import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-admin',
  templateUrl: './inicio-admin.component.html',
  styleUrls: ['./inicio-admin.component.scss']
})
export class InicioAdminComponent implements OnInit {

  secciones = [
    {
      nombre: 'Cotizaciones Vehículo Particular',
      url: '/admin/cotizacionesPersonaNatural'
    },
    {
      nombre: 'Compañía Logística',
      url: '/admin/cotizacionesEmpresa'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
