import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios-plan-persona-natural',
  templateUrl: './beneficios-plan-persona-natural.component.html',
  styleUrls: ['./beneficios-plan-persona-natural.component.scss']
})
export class BeneficiosPlanPersonaNaturalComponent implements OnInit {

  accesos = [
    'Ubicación en tiempo real',
    'Reconstrucción de ruta',
    'Reporte de viajes',
    'Reporte de historial',
    'Reporte de kilómetros acumulados',
    'Historial para consulta (30 días)',
    'Reportes de horas de trabajo',
    'Alerta de exceso de velocidad',
    'Alerta de botón de pánico',
    'Alerta de batería desconectada',
    'Alerta de giros bruscos'
  ];

  adicionales = [
    'Incluye instalación de GPS',
    'Bloqueador de motor',
    'Recuperación de vehículos robados',
    'Botón de pánico',
    'Central de emergencia 24x7x365'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
