<div class="backFooter text-white text-center pt-6 pb-2">
    <p class="pb-6">Oficina Central:<br>Cal. Germán Schereiber Nro. 276 Urb. Santa Ana - San Isidro<br><br> RPC: 944579506<br> <span><a class="text-white" href="mailto:info@orbitec.pe">info@orbitec.pe</a></span>
    </p>
    <div class="link-policies-ctn">
      <a class="text-white link-policies" [routerLink]="['/politica-de-privacidad']">Políticas de Privacidad</a>
    </div>
    <div class="col-md-5 mx-auto">
        <hr>
    </div>

    <p class="small">Orbitec 2018 - 2023. Todos los derechos reservados.</p>
    <!-- <p class="small">Orbitec 2018 - Todos los derechos reservados. Desarrollado por <a class="text-white" href="http://khapac.pe" target="blank">khapac.pe</a></p> -->
</div>
