
<div class="col-md-10 mx-auto">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb small">
      <li class="breadcrumb-item"><a href="#">Inicio</a></li>
      <li class="breadcrumb-item active" aria-current="page">Contacto</li>
    </ol>
  </nav>
  <div class="text-center">
    <h1 class="text-primary mb-4">Conversemos</h1>
  </div>

  <div class="row justify-content-between my-6">
    <div class="col-md-4">
      <h5 class="text-secondary">En Orbitec gestionamos y proveemos tecnología satelital para incrementar la rentabilidad de tu negocio.</h5>
      <div>
        <div class="bg-primary rounded text-white text-center p-3 mt-4 mb-3">
          <div class="mb-2">Central de emergencias: 24 Horas</div>
          <span class="h5 font-weight-bold">T: 721-0025 / C: 980951477</span>
        </div>
        <ul class="list">
          <li>Oficina Central: Cal. Germán Schereiber Nro. 276 Urb. Santa Ana - San Isidro</li>
          <li><span class="font-weight-bold text-primary"> Comercial y Post-venta:</span><br> RPC: 944579506</li>
          <li>Escríbenos a <a href="mailto:info@orbitec.pe">info@orbitec.pe</a></li>
        </ul>
      </div>
    </div>
    <div class="col-md-7">
        <form #f="ngForm" [formGroup]="formContacto" (ngSubmit)="onSubmit()" novalidate>
            <div class="row">
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre">
                    <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Celular</mat-label>
                    <input matInput formControlName="celular">
                    <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-12">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-12">
                    <mat-label>Mensaje</mat-label>
                    <textarea matInput rows="5" formControlName="mensaje"></textarea>
                    <mat-error *ngIf="errorMensaje()">{{errorMensaje()}}</mat-error>
                </mat-form-field>
                <div class="text-right col-12">
                    <button class="btn btn-primary" type="submit">Enviar mensaje</button>
                </div>
            </div>

        </form>
    </div>
  </div>

  <div class="mt-5 mb-7">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.1794077610484!2d-77.0143362846391!3d-12.081084745830465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c881b7b577d1%3A0x2554429385b8783a!2soficina%20602%2C%20C.%20Fermin%20Tanguis%20145%2C%20La%20Victoria%2015034!5e0!3m2!1ses!2spe!4v1659468741596!5m2!1ses!2spe"
            width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>




</div>
