import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    observer: true
  };

  banners;
  clientes;
  datos;
  servicios;

  constructor(
    router: Router,
    private ds: DataService
    ) {

    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    });

   }


  ngOnInit(): void {

    this.banners = this.ds.banners;
    this.clientes = this.ds.clientes;
    this.datos = this.ds.datos;
    this.servicios = this.ds.servicios;

  }

}
