import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LOCALE_ID} from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);

// Angularfire2
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';

import {SwiperModule} from 'ngx-swiper-wrapper';
import {SWIPER_CONFIG} from 'ngx-swiper-wrapper';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

// Angular Material
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CarouselModule} from 'ngx-owl-carousel-o';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PublicComponent} from './components/public/public.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {InicioComponent} from './components/inicio/inicio.component';
import {NosotrosComponent} from './components/nosotros/nosotros.component';
import {ContactoComponent} from './components/contacto/contacto.component';
import {ServiciosComponent} from './components/servicios/servicios.component';
import {RastreoLogisticoComponent} from './components/rastreo-logistico/rastreo-logistico.component';
import {DesarrolloSoftwareComponent} from './components/desarrollo-software/desarrollo-software.component';
import {MenuMobileComponent} from './components/menu-mobile/menu-mobile.component';
import {CotizarComponent} from './components/cotizar/cotizar.component';
import {PersonaNaturalComponent} from './components/cotizar/persona-natural/persona-natural.component';
import {EmpresaComponent} from './components/cotizar/empresa/empresa.component';
import {
  TerminosCondicionesModalComponent
} from './components/cotizar/terminos-condiciones-modal/terminos-condiciones-modal.component';
import {
  GraciasCotizadorPersonaNaturalComponent
} from './components/cotizar/gracias-cotizador-persona-natural/gracias-cotizador-persona-natural.component';
import {
  PoliticaPrivacidadModalComponent
} from './components/cotizar/politica-privacidad-modal/politica-privacidad-modal.component';
import {
  GraciasCotizadorEmpresaComponent
} from './components/cotizar/gracias-cotizador-empresa/gracias-cotizador-empresa.component';
import {AdminComponent} from './components/admin/admin.component';
import {
  CotizacionesPersonaNaturalComponent
} from './components/admin/cotizaciones-persona-natural/cotizaciones-persona-natural.component';
import {CotizacionesEmpresaComponent} from './components/admin/cotizaciones-empresa/cotizaciones-empresa.component';
import {LoginComponent} from './components/login/login.component';
import {HeaderAdminComponent} from './components/admin/header-admin/header-admin.component';
import {InicioAdminComponent} from './components/admin/inicio-admin/inicio-admin.component';
import {
  BeneficiosPlanEmpresaComponent
} from './components/cotizar/beneficios-plan-empresa/beneficios-plan-empresa.component';
import {
  BeneficiosPlanPersonaNaturalComponent
} from './components/cotizar/beneficios-plan-persona-natural/beneficios-plan-persona-natural.component';
import {AcreditadosComponent} from './components/cotizar/acreditados/acreditados.component';
import {
  EliminarCotizacionEmpresaComponent
} from './components/admin/cotizaciones-empresa/eliminar-cotizacion-empresa/eliminar-cotizacion-empresa.component';
import {
  EliminarCotizacionPersonaNaturalComponent
} from './components/admin/cotizaciones-persona-natural/eliminar-cotizacion-persona-natural/eliminar-cotizacion-persona-natural.component';
import {SaludoComponent} from './saludo/saludo.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {CarouselHolderComponent} from './components/cotizar/CarouselHolder.component';
import { PoliticaDePrivacidadComponent } from './components/politica-de-privacidad/politica-de-privacidad.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    HeaderComponent,
    CarouselHolderComponent,
    FooterComponent,
    InicioComponent,
    NosotrosComponent,
    ContactoComponent,
    ServiciosComponent,
    RastreoLogisticoComponent,
    DesarrolloSoftwareComponent,
    MenuMobileComponent,
    CotizarComponent,
    PersonaNaturalComponent,
    EmpresaComponent,
    TerminosCondicionesModalComponent,
    GraciasCotizadorPersonaNaturalComponent,
    PoliticaPrivacidadModalComponent,
    GraciasCotizadorEmpresaComponent,
    AdminComponent,
    CotizacionesPersonaNaturalComponent,
    CotizacionesEmpresaComponent,
    LoginComponent,
    HeaderAdminComponent,
    InicioAdminComponent,
    BeneficiosPlanEmpresaComponent,
    BeneficiosPlanPersonaNaturalComponent,
    AcreditadosComponent,
    EliminarCotizacionEmpresaComponent,
    EliminarCotizacionPersonaNaturalComponent,
    SaludoComponent,
    RedirectComponent,
    PoliticaDePrivacidadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatIconModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    MatBadgeModule,
    SwiperModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'es-PE'
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
}
