import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './components/public/public.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { PoliticaDePrivacidadComponent } from './components/politica-de-privacidad/politica-de-privacidad.component';
import { RastreoLogisticoComponent } from './components/rastreo-logistico/rastreo-logistico.component';
import { DesarrolloSoftwareComponent } from './components/desarrollo-software/desarrollo-software.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { CotizarComponent } from './components/cotizar/cotizar.component';
import { PersonaNaturalComponent } from './components/cotizar/persona-natural/persona-natural.component';
import { GraciasCotizadorPersonaNaturalComponent } from './components/cotizar/gracias-cotizador-persona-natural/gracias-cotizador-persona-natural.component';
import { EmpresaComponent } from './components/cotizar/empresa/empresa.component';
import { GraciasCotizadorEmpresaComponent } from './components/cotizar/gracias-cotizador-empresa/gracias-cotizador-empresa.component';

import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { CotizacionesPersonaNaturalComponent } from './components/admin/cotizaciones-persona-natural/cotizaciones-persona-natural.component';
import { CotizacionesEmpresaComponent } from './components/admin/cotizaciones-empresa/cotizaciones-empresa.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import {SaludoComponent} from './saludo/saludo.component';
import { RedirectComponent } from './components/redirect/redirect.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: '', component: PublicComponent,
    children: [
      { path: '', component: InicioComponent },
      { path: 'servicios', component: ServiciosComponent },
      { path: 'servicios/rastreoLogistico', component: RastreoLogisticoComponent },
      { path: 'servicios/desarrolloSoftware', component: DesarrolloSoftwareComponent },
      { path: 'nosotros', component: NosotrosComponent },
      { path: 'contacto', component: ContactoComponent },
      { path: 'cotizar', component: CotizarComponent },
      { path: 'cotizar/personaNatural', component: PersonaNaturalComponent },
      { path: 'cotizar/personaNatural/:id/gracias', component: GraciasCotizadorPersonaNaturalComponent },
      { path: 'cotizar/empresa', component: EmpresaComponent },
      { path: 'cotizar/empresa/:id/gracias', component: GraciasCotizadorEmpresaComponent },
      { path: 'politica-de-privacidad', component: PoliticaDePrivacidadComponent }
    ]
  },
  { path: 'saludo2021', component: SaludoComponent },
  { path: 'login_admin', component: LoginComponent},
  { path: 'login', component: RedirectComponent},
  {
    path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', component: InicioAdminComponent },
      { path: 'cotizacionesPersonaNatural', component: CotizacionesPersonaNaturalComponent },
      { path: 'cotizacionesEmpresa', component: CotizacionesEmpresaComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
