<div>

    <div class="d-flex justify-content-end  mb-2 mb-md-3">
        <button mat-icon-button (click)="cancelar()"><mat-icon>close</mat-icon> </button>
    </div>

    <div class="mb-3 mb-md-4 text-center">
        <p class="">¿Seguro que desea eliminar la cotización? Esta acción no se puede deshacer.</p>
    </div>

    <div>
        <button type="submit" class="btn btn-danger btn-block font-weight-bold py-2 px-3" (click)="eliminarReserva(data.id)">
            <span class="myColorSpinner d-flex justify-content-center" *ngIf="deshabilitado"><mat-spinner class="text-center"  [diameter]="24"></mat-spinner></span>
            <div *ngIf="!deshabilitado">
                <span>Eliminar cotización</span>
            </div>
        </button>
        <button class="btn btn-link btn-block text-dark" (click)="cancelar()">Cancelar</button>
    </div>

</div>