import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rastreo-logistico',
  templateUrl: './rastreo-logistico.component.html',
  styleUrls: ['./rastreo-logistico.component.scss']
})
export class RastreoLogisticoComponent implements OnInit {

  alcances: any;
  alertas: any;
  accesorios: any;
  monitoreos: any;

  constructor() { }

  ngOnInit() {

    this.monitoreos = [
      {
        image: './assets/images/iconBotonPanico.svg',
        title: 'Alertas y notificaciones',
        description: 'Reciba alertas y notificaciones instantáneas cuando sus unidades irrumpan reglas preestablecidas de comportamiento, tales como excesos de velocidad, salidas y entradas de geocercas, cuando exista alguna desconexión de batería o cuando su maquinaria este siendo utilizada fuera de las horas de servicio contratadas.'
      },
      {
        image: './assets/images/iconEntradaSalidaZonas.svg',
        title: 'Patrón de conducción',
        description: 'Usted podrá estimar la calidad de conducción de sus colaboradores. La valoración se basa en tres criterios principales: intensidad de aceleración o frenado y giros bruscos. Con esta herramienta no solo permite reducir los gastos en el mantenimiento, sino también garantiza la seguridad de la carga si se trata de una empresa de transporte.'
      },
      {
        image: './assets/images/iconVelocidad.svg',
        title: 'Control de velocidades',
        description: 'Tenga controlada su flota, generando alarmas por exceso en los límites de velocidad autorizados.'
      },
      {
        image: './assets/images/iconDesviacionRutas.svg',
        title: 'Plataforma multiusuario',
        description: 'Gestione los permisos de los usuarios y sub-usuarios que van a monitorear su flota.'
      },
      {
        image: './assets/images/iconRpm.svg',
        title: 'Reportes personalizados',
        description: 'La plataforma a través de diversas tablas le permite crear informes adaptados a sus necesidades.'
      },
      {
        image: './assets/images/iconEntradaSalidaZonas.svg',
        title: 'Vista de la calle y el tráfico',
        description: 'Planifique las rutas de acuerdo a las condiciones momentáneas de tráfico. Observe calles y edificios en 360° en cualquier punto de la ruta.'
      },
      {
        image: './assets/images/iconHorasMarcha.svg',
        title: 'Estadísticas de recorridos',
        description: 'Acceda a la información sobre cada viaje: la duración, la longitud, la velocidad promedio. Las estadísticas rápidas ayudan a encontrar los datos requeridos sin necesidad de crear informes detallados.'
      },
      {
        image: './assets/images/iconParadasAutorizadas.svg',
        title: 'Visualización eficaz de los recorridos',
        description: 'Visualice y comparta recorridos en el mapa.'
      }

    ];

    this.alertas = [
      {
        image: './assets/images/iconBotonPanico.svg',
        title: 'Botón de Pánico',
        description: 'Presionando el botón de pánico, el dispositivo pondrá en alerta a nuestra central de emergencia para actuar en caso de accidentes, robos, secuestros y asaltos.'
      },
      {
        image: './assets/images/iconBateria.svg',
        title: 'Batería desconectada',
        description: 'Registra un evento si el GPS se queda sin energía eléctrica proveniente de la batería principal del vehículo.'
      }
    ];

    this.alcances = [
      {
        image: './assets/images/iconBotonPanico.svg',
        title: 'Botón de Pánico',
        description: 'Sistema de alerta a nuestra central de monitoreo, a UD. y a un tercero, las 24 hs. los 365 días del año.'
      },

      {
        image: './assets/images/iconAntiSabotaje.svg',
        title: 'Anti sabotaje',
        description: 'Sepa si el GPS de su unidad está siendo manipulado o desconectado.'
      },

      {
        image: './assets/images/iconBateria.svg',
        title: 'Batería desconectada',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tempus orci id dui placerat.'
      },

      {
        image: './assets/images/iconParadasAutorizadas.svg',
        title: 'Paradas autorizadas',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tempus orci id dui placerat.'
      },

      {
        image: './assets/images/iconVelocidad.svg',
        title: 'Velocidad',
        description: 'Tenga controlada su flota en la vía pública, generando alarmas por excesos en los límites de velocidad autorizados.'
      },

      {
        image: './assets/images/iconEntradaSalidaZonas.svg',
        title: 'Entrada y salida de zonas',
        description: 'Conozca las zonas por donde circulan sus vehículos, recibiendo alertas por entrada y/o salidas de zonas prohibidas y/o autorizadas.'
      },

      {
        image: './assets/images/iconHorasMarcha.svg',
        title: 'Horas de marcha',
        description: 'Medimos la cantidad de horas de marcha de cualquier tipo de vehículo (inclusive maquinaria pesada), para facilitar posteriormente su administración.'
      },

      {
        image: './assets/images/iconRpm.svg',
        title: 'RPM',
        description: 'Ayudamos a prolongar la vida de su vehículo notificándole los excesos de RPM.'
      },

      {
        image: './assets/images/iconDesviacionRutas.svg',
        title: 'Desviación de rutas',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tempus orci id dui placerat.'
      },

    ];

    this.accesorios = [
      {
        image: './assets/images/iconSensorCombustible.svg',
        title: 'Sensor de consumo de combustible',
        description: 'Obtiene la información confiable sobre el nivel de combustible en el tanque del vehículo'
      },

      {
        image: './assets/images/iconSensorTemperatura.svg',
        title: 'Sensor de temperatura',
        description: 'Controlamos que su mercaderia se mantenga dentro de la cadena de frio correspondiente, informando en tiempo real la temperatura de su caja termica.'
      },

      {
        image: './assets/images/iconDesengancheAcoplado.svg',
        title: 'Desenganche de acoplado',
        description: 'Monitoreamos el estado de su acoplado (enganchado / desenganchado), determinando el punto geografico donde se desengancho.'
      },

      {
        image: './assets/images/iconAperturaPuerta.svg',
        title: 'Apertura de puertas',
        description: 'Tenga conocimiento del lugar, fecha y hora de las aperturas delas puertas de su vehículo (cabina, zona de carga, caja térmica, etc.)'
      },

      {
        image: './assets/images/iconApagadoRemoto.svg',
        title: 'Apagado remoto',
        description: 'Detenga el motor de su vehículo una vez confirmado el incidente simplemente llamando a nuestra central de monitoreo.'
      },

      {
        image: './assets/images/iconVozEscuchaCabina.svg',
        title: 'Voz y escucha en cabina',
        description: 'Bocina y micrófono colocados en el vehículo para tener comunicación directa con el conductor.'
      },

      {
        image: './assets/images/iconSensorTolvaAbierta.svg',
        title: 'Sensor - Tolva abierta/cerrada',
        description: 'Tenga conocimiento del lugar, fecha y hora de las aperturas delas puertas de su vehículo (cabina, zona de carga, caja térmica, etc.)'
      },

      {
        image: './assets/images/iconAperturaPestillos.svg',
        title: 'Apertura de pestillos',
        description: 'Permite activar los pestillos de su vehículo de manera remota.'
      },

      {
        image: './assets/images/iconButtonKey.svg',
        title: 'I-Button Key',
        description: 'Llaves codificadas con receptores específicos que permiten aplicaciones de inmovilización e identificación del conductor para servicios de seguridad.'
      },

      {
        image: './assets/images/iconLectorRfid.svg',
        title: 'Lector RFID',
        description: 'El RFID concede acceso solo a las personas que tienen tarjetas de clave para encender el vehículo.'
      },

      {
        image: './assets/images/iconBuzzer.svg',
        title: 'Buzzer',
        description: 'Dispositivo que permite una alerta sonora en el interior del vehículo, la cual se activa si el dispositivo excede una velocidad permtida.'
      },

    ];
  }

}
