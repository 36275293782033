import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Cotizacion } from '../classes/cotizacion';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afs: AngularFirestore
  ) { }

  addContacto(datosForm) {
    return this.afs.collection('mensajes').add(datosForm);
  }

  addCotizacionEmpresa(id, formDatos) {
    return this.afs.collection('cotizacionesEmpresa').doc(id).set({
      datos: formDatos,
    });
  }

  getCotizacionEmpresa(id) {
    return this.afs.doc('cotizacionesEmpresa/' + id).valueChanges();
  }

  addCotizacionPersonaNatural(id, formDatos) {
    return this.afs.collection('cotizacionesPersonaNatural').doc(id).set({
      datos: formDatos,
    });
  }

  getCotizacionPersonaNatural(id) {
    return this.afs.doc('cotizacionesPersonaNatural/' + id).valueChanges();
  }



  getAllCotizacionesPersonaNatural() {
    return this.afs.collection('cotizacionesPersonaNatural', ref => ref
    .orderBy('datos.fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllCotizacionesEmpresa() {
    return this.afs.collection('cotizacionesEmpresa', ref => ref
    .orderBy('datos.fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  deleteCotizacionEmpresa(idCotizacion) {
    return this.afs.collection('cotizacionesEmpresa').doc(idCotizacion).delete();
  }

  deleteCotizacionPersonaNatural(idCotizacion) {
    return this.afs.collection('cotizacionesPersonaNatural').doc(idCotizacion).delete();
  }



}
