import { Component, OnInit } from '@angular/core';
import { MenuMobileComponent } from '../menu-mobile/menu-mobile.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links;

  constructor(
    private ds: DataService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.links = this.ds.links;
  }

  openModalMenuMobile() {
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: 'myModalFullScreen',
    });
    dialogRef.afterClosed().subscribe();
  }

  // getRoute() {
  //   if (this.router.url === '/') {
  //    return 'navbarFloat';
  //   }
  // }

  // getRouteActive(url) {
  //   return this.router.url === url;
  // }

}
