<div class="col-md-10 mx-auto">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb mb-0 small">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/cotizar">Cotizar</a></li>
      <li class="breadcrumb-item active">Persona Natural</li>
    </ol>
  </nav>
</div>

<div class="bg-light-blue">
  <div class="bannerResultadoCotizador">
    <div class="col-sm-12 mx-auto d-flex justify-content-center h-100">
      <div class="text-white col-sm-12 col-md-8 col-lg-7 d-flex justify-content-center align-items-center">
        <div class="text-left desk-view">
          <p *ngIf="cotizacion" class="h4  mb-2 font-weight-700">Hola {{cotizacion.datos.nombre | titlecase}},</p>
          <p class="h5 w-75 mb-0">Te mostramos el detalle de tu cotización.</p>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fvehiculos.svg?alt=media&token=90f1cfc2-e278-4061-b740-ebc440ad51a0"
          alt=""
          class="ml-4 mr-1">
        <div class="text-left mobile-view">
          <p *ngIf="cotizacion" class="h5  mb-2 font-weight-700">Hola {{cotizacion.datos.nombre | titlecase}},</p>
          <p class="h6 w-75 mb-0">Te mostramos el detalle de tu cotización.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 d-flex flex-row flex-wrap justify-content-center align-items-start m-0 mt-3 pt-5">
    <div class="d-none d-sm-none d-md-flex  justify-content-center px-3 align-items-center flex-column"
         *ngFor="let el of planes">
      <button class="btns h4 position-relative btn-secondary radius-sm medium w-75 font-weight-800 px-3">
        Plan {{ el.tipo }}</button>
      <div class=" px-lg-1  d-flex justify-content-center px-md-0 py-1" style="max-width: 360px!important"
           *ngIf="cotizacion">
        <div class=" bg-white columnResultadoCotizador columnResultadoCotizador--largeaut border-secondary border p-3">
          <div class="d-flex justify-content-center">
            <div class="text-center d-flex flex-column flex-wrap justify-content-center align-items-center">
              <div class="pb-2 pt-1">
                <img class="mx-1"
                     src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/calendar_vector.svg?alt=media&token=20acd516-3bb2-42be-a0c6-15352df8a480"
                     alt="">
              </div>
              <p class="h6 text-secondary  mb-1">Servicio Anual</p>
              <p *ngIf="el.costo===400" class="text-success h1 mb-2 font-weight-700">S/ {{ el.costo }} </p>
              <p *ngIf="el.costo===500" class="text-secondary h1 mb-2 font-weight-700">S/ {{ el.costo }} </p>
              <p *ngIf="el.costo===590" class="text-primary h1 mb-2 font-weight-700">S/ {{ el.costo }} </p>
              <p class="style">Precio variable dependiendo de la cantidad de vehículos</p>
              <hr *ngIf="el.costo===400" class="hrgreen"/>
              <hr *ngIf="el.costo===500" class="hrblue"/>
              <hr *ngIf="el.costo===590" class="hrred"/>
              <div class="pb-2 pt-1">
              </div>
              <div *ngIf="el.costo===400" class="colorgreen">
                <!--                <hr class="v-line">-->

                <!-- con fe -->
                <div class="clearfix">
                  <div class="w-49 float-left">

                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F1_izquierda_Central%20de%20monitoreo%2024h_plan400.svg?alt=media&token=b0e94620-dda6-4581-a78b-b40740cd3981">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Central de monitoreo 24h</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F2_izquierda_Alertas%20exceso%20de%20velocidad_plan400.svg?alt=media&token=60210705-8cae-438b-bb3d-4eac486a6fe0">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alertas exceso de velocidad</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F3_izquierda_Hist%C3%B3rico%20de%20rutas_plan400.svg?alt=media&token=551cbe44-79c0-4cb9-aab9-23a7a7c536bd">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Histórico de rutas</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F4_izquierda_Kil%C3%B3metros%20de%20recorrido_plan400.svg?alt=media&token=48b1d014-82cf-4883-b496-0b437afbdefc">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span> Kilómetros de recorrido</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F5_izquierda_Tiempo%20detenido_plan400.svg?alt=media&token=cedaeb0b-9f82-4c4e-a046-6e150a1d2c56">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo detenido</span>
                      </div>
                    </div>

                  </div>

                  <div class="w-2 float-left">
                    <hr class="v-line">
                  </div>

                  <div class="w-49 float-left">
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan400.svg?alt=media&token=f7ab6b73-d4cf-4679-8480-e554ce896b2a">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Acceso al aplicativo móvil</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F2_derecha_Alarma%20bater%C3%ADa%20desconectada_plan400.svg?alt=media&token=b6f5d265-d461-4423-90ab-d8fa94e8c462">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma batería desconectada</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F3_derecha_Reporte%20de%20viajes%20realizados_plan400.svg?alt=media&token=1e2fb7a7-d68b-4770-9295-9676567827c0">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Reporte de viajes realizados</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F4_derecha_Tiempo%20conduciendo_plan400.svg?alt=media&token=a63fa217-36d0-457f-83fc-765e7e3b0e6c">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo conduciendo</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F5_derecha_Dashboard%20gr%C3%A1fico_plan400.svg?alt=media&token=b50ae1b8-2404-4031-b0f4-e842fe694788">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Dashboard gráfico</span>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="pb-2 pt-1">
                </div>
                <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                   href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                   target="blank">Contactar a un asesor</a>
              </div>

              <div *ngIf="el.costo===500" class="colorblue">

                <div class="clearfix">
                  <div class="w-49 float-left">

                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F1_izquierda_Central%20de%20monitoreo%2024h_plan500.svg?alt=media&token=cee855ba-ac41-47b4-a4ac-8d2998361e3e">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Central de monitoreo 24h</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F2_izquierda_Alertas%20exceso%20de%20velocidad_plan500.svg?alt=media&token=0ea4a3f9-2767-4d5f-ab32-89b030801653">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma botón de pánico</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F3_izquierda_Alertas%20exceso%20de%20velocidad_plan500.svg?alt=media&token=28292231-2ccd-4ea5-9e89-f26e1225436c">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alertas exceso de velocidad</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F4_izquierda_Reporte%20de%20viajes%20realizados_plan500.svg?alt=media&token=7a15ac2f-a14c-442d-8a23-0a3ed0e83d0b">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Reporte de viajes realizados</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F5_izquierda_Tiempo%20conduciendo_plan500.svg?alt=media&token=052f0549-2c3e-46ef-b3c8-36093293b5cb">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo conduciendo</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F6_izquierda_Dashboard%20gr%C3%A1fico_plan500.svg?alt=media&token=83c3ee96-db9e-4fb6-94e8-1f789062e3c4">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Dashboard gráfico</span>
                      </div>
                    </div>


                  </div>

                  <div class="w-2 float-left">
                    <hr class="v-lineblue">
                  </div>

                  <div class="w-49 float-left">
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan500.svg?alt=media&token=ad8ab139-91bb-4229-ba5e-0ea293317140">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Acceso al aplicativo móvil</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F2_derecha_Alarma%20bater%C3%ADa%20desconectada_plan500.svg?alt=media&token=f3c22202-d162-4f92-8227-548d1b6b7fed">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma batería desconectada</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F3_derecha_Hist%C3%B3rico%20de%20rutas_plan500.svg?alt=media&token=ab115f40-9200-4aea-89a7-57153f79c02d">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Histórico de rutas</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F4_derecha_Kil%C3%B3metros%20de%20recorrido_plan500.svg?alt=media&token=aea7e9e3-8ae7-4782-97a3-d422f9f6444b">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Kilómetros de recorrido</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F5_derecha_Tiempo%20detenido_plan500.svg?alt=media&token=43fa7b99-575e-40c1-a3ef-829a003b5fa6">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo detenido</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F6_derecha_Bloqueo%20de%20motor_plan500.svg?alt=media&token=c8a47ac9-51d0-43e1-b3eb-491b35220195">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Bloqueo de motor</span>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="pb-2 pt-1">
                </div>
                <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                   href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                   target="blank">Contactar a un asesor</a>
              </div>

              <div *ngIf="el.costo===590" class="color">

                <div class="clearfix">
                  <div class="w-49 float-left">

                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F1_izquierda_Central%20de%20monitoreo%2024h_plan590.svg?alt=media&token=455377e9-dd35-476c-a7a7-88d4805a5757">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Central de monitoreo 24h</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F2_izquierda_Acceso%20a%20plataforma%20web_plan590.svg?alt=media&token=7932f9e2-9a11-4504-9d24-3cd68b0e4696">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Acceso a platafroma web</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F3_izquierda_Alarma%20bater%C3%ADa%20desconectada_plan590.svg?alt=media&token=ced5b0f8-c4c1-4f70-bf34-db4baafb1d27">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma batería desconectada</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F4_izquierda_Alertas%20personalizables_plan590.svg?alt=media&token=6ac189ef-0648-41ed-a76c-3ad85445b1ec">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alertas personalizables</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F5_izquierda_Creaci%C3%B3n%20de%20geocercas_plan590.svg?alt=media&token=d97431b7-54ab-4a7b-a6cf-a01c635a61d3">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Creación de geocercas</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F6_izquierda_Reporte%20de%20viajes%20realizados_plan590.svg?alt=media&token=3b10961b-24b0-4cd5-8b4b-3f6fc3973d04">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Reportes de viajes realizados</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F7_izquierda_Tiempo%20conduciendo_plan590.svg?alt=media&token=dfa38edf-830a-4053-bbfd-0f815f988684">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo conduciendo</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F8_izquierda_Dashboard%20gr%C3%A1fico_plan590.svg?alt=media&token=5f4521d3-b713-4078-b0d3-aae6403bd003">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Dashboard gráfico</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F9_izquierda_Recuperaci%C3%B3n%20de%20veh%C3%ADculo_plan590.svg?alt=media&token=7da17c67-924a-4d7f-b064-d43650338a78">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Recuperación de vehículo</span>
                      </div>
                    </div>

                  </div>

                  <div class="w-2 float-left">
                    <hr class="v-linered">
                  </div>

                  <div class="w-49 float-left">
                    <div class="row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan590.svg?alt=media&token=a3721354-63b1-4e00-9f3e-a8651c3dfde4">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Acceso al aplicativo móvil</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F2_derecha_Alertas%20exceso%20de%20velocidad_plan590.svg?alt=media&token=647add2d-65eb-4534-a8c5-0cb2e6ca291e">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma botón de pánico</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F3_derecha_Alertas%20exceso%20de%20velocidad_plan590.svg?alt=media&token=bcec4644-07ad-497d-b99f-83c6a05b04e8">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Alarma exceso de velocidad</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F4_derecha_Acceso%20total%20a%20reportes_plan590.svg?alt=media&token=73d826c6-c804-43f3-8c58-17c8d1d94ba0">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Acceso total a reportes</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F5_derecha_Hist%C3%B3rico%20de%20rutas_plan590.svg?alt=media&token=c7bb96fa-d8fd-4b1e-90e9-ce38cd3b6044">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Hstórico de rutas</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F6_derecha_Kil%C3%B3metros%20de%20recorrido_plan590.svg?alt=media&token=a2ae4f61-e360-4fb4-a054-e0962bca25fc">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Kiolómetro de recorrido</span>
                      </div>
                    </div>
                    <div class=" row m-0">
                      <div class="px-1">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F7_derecha_Tiempo%20detenido_plan590.svg?alt=media&token=48385121-1750-48c0-8249-70892de22ab8">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Tiempo detenido</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F8_derecha_Bloqueo%20de%20motor_plan590.svg?alt=media&token=ff4dd46c-d14d-473b-8588-1480f4b013ef">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Bloqueo de motor*</span>
                      </div>
                    </div>

                    <div class="row m-0">
                      <div class="px-1 ">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F9_derecha_Creaci%C3%B3n%20de%20multi%20usuarios_plan590.svg?alt=media&token=391d95ed-2445-44e1-859e-be08c01334ee">
                      </div>
                      <div class="col text-left mt-1 p-0">
                        <span>Creación de multi usuarios</span>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="pb-2 pt-1">
                </div>
                <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                   href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                   target="blank">Contactar a un asesor</a>
              </div>

              <p class="small mt-2 text-muted mb-0">* Los precios incluyen I.G.V.</p>
              <p class="small text-muted">* Los precios expresados son por un (1) vehículo.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     desktop view -->
  </div>
  <owl-carousel-o #owlCar class=" d-md-none d-lg-none d-xl-none d-flex w-100 " [options]="customOptions">

    <ng-container>
      <ng-template carouselSlide *ngFor="let el of planes" [width]="300" class="mnw-300  w-400">
        <div>
          <button class=" imgButtonCarousel imgButtonCarousel--left ">
            <img *ngIf="el.iz" (click)="owlCar.prev()" title="desliza a la iz" class=""
                 src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/arrowleft.svg?alt=media&token=f95e24f7-a202-4764-b82b-1aaca0a0abc2"
                 alt="<">
          </button>
          <button class=" imgButtonCarousel imgButtonCarousel--right">
            <img *ngIf="el.der" (click)="owlCar.next()" title="desliza a la der" class=" "
                 src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/arrowright.svg?alt=media&token=9383bd56-6efb-4957-a8f2-8bd60eafe93e"
                 alt=">">
          </button>
        </div>
        <div [id]="el.id" class="d-flex justify-content-center mx-1 align-items-center flex-column">
          <button class="btn btn-secondary radius-sm medium w-75 font-weight-800 px-3">Plan {{ el.tipo }}</button>
          <div class=" px-lg-4  d-flex justify-content-center px-md-0 py-3 py-md-5" *ngIf="cotizacion">
            <div
              class=" bg-white columnResultadoCotizador  columnResultadoCotizador--largeaut border-secondary border p-3">
              <div class="d-flex justify-content-center">
                <div class="text-center d-flex flex-column flex-wrap justify-content-center align-items-center">
                  <div class="pb-2 pt-1">
                    <img class="mx-1"
                         src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/calendar_vector.svg?alt=media&token=20acd516-3bb2-42be-a0c6-15352df8a480"
                         alt="">
                  </div>
                  <p class="h6 text-secondary  mb-1">Servicio Anual</p>
                  <p *ngIf="el.costo===400" class="text-success h1 mb-2 font-weight-800">S/ {{ el.costo }} </p>
                  <p *ngIf="el.costo===500" class="text-secondary h1 mb-2 font-weight-800">S/ {{ el.costo }} </p>
                  <p *ngIf="el.costo===590" class="text-primary h1 mb-2 font-weight-800">S/ {{ el.costo }} </p>
                  <p class="style">Precio variable dependiendo de la cantidad de vehículos</p>
                  <hr *ngIf="el.costo===400" class="hrgreen"/>
                  <hr *ngIf="el.costo===500" class="hrblue"/>
                  <hr *ngIf="el.costo===590" class="hrred"/>
                  <div class="pb-2 pt-1">
                  </div>
                  <div *ngIf="el.costo===400" class="colorgreen">
                    <!--                <hr class="v-line">-->

                    <!-- con fe -->
                    <div class="clearfix">
                      <div class="w-49 float-left">

                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F1_izquierda_Central%20de%20monitoreo%2024h_plan400.svg?alt=media&token=b0e94620-dda6-4581-a78b-b40740cd3981">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Central de monitoreo 24h</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F2_izquierda_Alertas%20exceso%20de%20velocidad_plan400.svg?alt=media&token=60210705-8cae-438b-bb3d-4eac486a6fe0">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alertas exceso de velocidad</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F3_izquierda_Hist%C3%B3rico%20de%20rutas_plan400.svg?alt=media&token=551cbe44-79c0-4cb9-aab9-23a7a7c536bd">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Histórico de rutas</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F4_izquierda_Kil%C3%B3metros%20de%20recorrido_plan400.svg?alt=media&token=48b1d014-82cf-4883-b496-0b437afbdefc">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span> Kilómetros de recorrido</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F5_izquierda_Tiempo%20detenido_plan400.svg?alt=media&token=cedaeb0b-9f82-4c4e-a046-6e150a1d2c56">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo detenido</span>
                          </div>
                        </div>

                      </div>

                      <div class="w-2 float-left">
                        <hr class="v-line">
                      </div>

                      <div class="w-49 float-left">
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan400.svg?alt=media&token=f7ab6b73-d4cf-4679-8480-e554ce896b2a">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Acceso al aplicativo móvil</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F2_derecha_Alarma%20bater%C3%ADa%20desconectada_plan400.svg?alt=media&token=b6f5d265-d461-4423-90ab-d8fa94e8c462">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma batería desconectada</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F3_derecha_Reporte%20de%20viajes%20realizados_plan400.svg?alt=media&token=1e2fb7a7-d68b-4770-9295-9676567827c0">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Reporte de viajes realizados</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F4_derecha_Tiempo%20conduciendo_plan400.svg?alt=media&token=a63fa217-36d0-457f-83fc-765e7e3b0e6c">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo conduciendo</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fgreen%2F5_derecha_Dashboard%20gr%C3%A1fico_plan400.svg?alt=media&token=b50ae1b8-2404-4031-b0f4-e842fe694788">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Dashboard gráfico</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="pb-2 pt-1">
                    </div>
                    <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                       href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                       target="blank">Contactar a un asesor</a>
                  </div>

                  <div *ngIf="el.costo===500" class="colorblue">

                    <div class="clearfix">
                      <div class="w-49 float-left">

                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F1_izquierda_Central%20de%20monitoreo%2024h_plan500.svg?alt=media&token=cee855ba-ac41-47b4-a4ac-8d2998361e3e">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Central de monitoreo 24h</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F2_izquierda_Alertas%20exceso%20de%20velocidad_plan500.svg?alt=media&token=0ea4a3f9-2767-4d5f-ab32-89b030801653">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma botón de pánico</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F3_izquierda_Alertas%20exceso%20de%20velocidad_plan500.svg?alt=media&token=28292231-2ccd-4ea5-9e89-f26e1225436c">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alertas exceso de velocidad</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F4_izquierda_Reporte%20de%20viajes%20realizados_plan500.svg?alt=media&token=7a15ac2f-a14c-442d-8a23-0a3ed0e83d0b">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Reporte de viajes realizados</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F5_izquierda_Tiempo%20conduciendo_plan500.svg?alt=media&token=052f0549-2c3e-46ef-b3c8-36093293b5cb">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo conduciendo</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F6_izquierda_Dashboard%20gr%C3%A1fico_plan500.svg?alt=media&token=83c3ee96-db9e-4fb6-94e8-1f789062e3c4">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Dashboard gráfico</span>
                          </div>
                        </div>


                      </div>

                      <div class="w-2 float-left">
                        <hr class="v-lineblue">
                      </div>

                      <div class="w-49 float-left">
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan500.svg?alt=media&token=ad8ab139-91bb-4229-ba5e-0ea293317140">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Acceso al aplicativo móvil</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F2_derecha_Alarma%20bater%C3%ADa%20desconectada_plan500.svg?alt=media&token=f3c22202-d162-4f92-8227-548d1b6b7fed">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma batería desconectada</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F3_derecha_Hist%C3%B3rico%20de%20rutas_plan500.svg?alt=media&token=ab115f40-9200-4aea-89a7-57153f79c02d">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Histórico de rutas</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F4_derecha_Kil%C3%B3metros%20de%20recorrido_plan500.svg?alt=media&token=aea7e9e3-8ae7-4782-97a3-d422f9f6444b">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Kilómetros de recorrido</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F5_derecha_Tiempo%20detenido_plan500.svg?alt=media&token=43fa7b99-575e-40c1-a3ef-829a003b5fa6">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo detenido</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fblue%2F6_derecha_Bloqueo%20de%20motor_plan500.svg?alt=media&token=c8a47ac9-51d0-43e1-b3eb-491b35220195">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Bloqueo de motor</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="pb-2 pt-1">
                    </div>
                    <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                       href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                       target="blank">Contactar a un asesor</a>
                  </div>

                  <div *ngIf="el.costo===590" class="color">

                    <div class="clearfix">
                      <div class="w-49 float-left">

                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F1_izquierda_Central%20de%20monitoreo%2024h_plan590.svg?alt=media&token=455377e9-dd35-476c-a7a7-88d4805a5757">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Central de monitoreo 24h</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F2_izquierda_Acceso%20a%20plataforma%20web_plan590.svg?alt=media&token=7932f9e2-9a11-4504-9d24-3cd68b0e4696">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Acceso a platafroma web</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F3_izquierda_Alarma%20bater%C3%ADa%20desconectada_plan590.svg?alt=media&token=ced5b0f8-c4c1-4f70-bf34-db4baafb1d27">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma batería desconectada</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F4_izquierda_Alertas%20personalizables_plan590.svg?alt=media&token=6ac189ef-0648-41ed-a76c-3ad85445b1ec">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alertas personalizables</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F5_izquierda_Creaci%C3%B3n%20de%20geocercas_plan590.svg?alt=media&token=d97431b7-54ab-4a7b-a6cf-a01c635a61d3">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Creación de geocercas</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F6_izquierda_Reporte%20de%20viajes%20realizados_plan590.svg?alt=media&token=3b10961b-24b0-4cd5-8b4b-3f6fc3973d04">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Reportes de viajes realizados</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F7_izquierda_Tiempo%20conduciendo_plan590.svg?alt=media&token=dfa38edf-830a-4053-bbfd-0f815f988684">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo conduciendo</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F8_izquierda_Dashboard%20gr%C3%A1fico_plan590.svg?alt=media&token=5f4521d3-b713-4078-b0d3-aae6403bd003">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Dashboard gráfico</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F9_izquierda_Recuperaci%C3%B3n%20de%20veh%C3%ADculo_plan590.svg?alt=media&token=7da17c67-924a-4d7f-b064-d43650338a78">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Recuperación de vehículo</span>
                          </div>
                        </div>

                      </div>

                      <div class="w-2 float-left">
                        <hr class="v-linered">
                      </div>

                      <div class="w-49 float-left">
                        <div class="row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F1_derecha_Acceso%20al%20aplicativo%20m%C3%B3vil_plan590.svg?alt=media&token=a3721354-63b1-4e00-9f3e-a8651c3dfde4">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Acceso al aplicativo móvil</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F2_derecha_Alertas%20exceso%20de%20velocidad_plan590.svg?alt=media&token=647add2d-65eb-4534-a8c5-0cb2e6ca291e">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma botón de pánico</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F3_derecha_Alertas%20exceso%20de%20velocidad_plan590.svg?alt=media&token=bcec4644-07ad-497d-b99f-83c6a05b04e8">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Alarma exceso de velocidad</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F4_derecha_Acceso%20total%20a%20reportes_plan590.svg?alt=media&token=73d826c6-c804-43f3-8c58-17c8d1d94ba0">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Acceso total a reportes</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F5_derecha_Hist%C3%B3rico%20de%20rutas_plan590.svg?alt=media&token=c7bb96fa-d8fd-4b1e-90e9-ce38cd3b6044">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Hstórico de rutas</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F6_derecha_Kil%C3%B3metros%20de%20recorrido_plan590.svg?alt=media&token=a2ae4f61-e360-4fb4-a054-e0962bca25fc">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Kiolómetro de recorrido</span>
                          </div>
                        </div>
                        <div class=" row m-0">
                          <div class="px-1">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F7_derecha_Tiempo%20detenido_plan590.svg?alt=media&token=48385121-1750-48c0-8249-70892de22ab8">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Tiempo detenido</span>
                          </div>
                        </div>
                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F8_derecha_Bloqueo%20de%20motor_plan590.svg?alt=media&token=ff4dd46c-d14d-473b-8588-1480f4b013ef">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Bloqueo de motor*</span>
                          </div>
                        </div>

                        <div class="row m-0">
                          <div class="px-1 ">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/cotizador%20nuevo%2Fizquierda%2F9_derecha_Creaci%C3%B3n%20de%20multi%20usuarios_plan590.svg?alt=media&token=391d95ed-2445-44e1-859e-be08c01334ee">
                          </div>
                          <div class="col text-left mt-1 p-0">
                            <span>Creación de multi usuarios</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="pb-2 pt-1">
                    </div>
                    <a class="btn btn-primary font-weight-100 btn-sm py-2 px-3"
                       href="{{ urlContacto(cotizacion.datos.nombre, el.tipo, cotizacion.datos.tipoVehiculo, idCotizacion) }}"
                       target="blank">Contactar a un asesor</a>
                  </div>

                  <p class="small mt-2 text-muted mb-0">* Los precios incluyen I.G.V.</p>
                  <p class="small text-muted">* Los precios expresados son por un (1) vehículo.</p>
                </div>
              </div>
            </div>


          </div>
          <!--     desktop view -->
        </div>
      </ng-template>
    </ng-container>

  </owl-carousel-o>
</div>
<div class="col-12 bannerGift bg-primary">
  <p class="h2 font-weight-800 text-white"> Tecnología a la medida de tu negocio</p>
  <div class="orbitec-gift">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/Gif-Logistico.gif?alt=media&token=5e79d344-71bd-40a5-ac1f-a4513b3d97ed"
      alt="">
  </div>
</div>
<div class="col-12 bg-light-blue py-5">
  <div class="text-center mt-3 mt-md-4">
    <img width="150" height="85"
         src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/WhatsApp-logo%201.png?alt=media&token=37e371cb-c472-49d2-a76b-456aa8a29946"
         alt="WhatsApp">
    <p class="h5 font-weight-800 py-4">¡Empieza ahora y toma control de tu vehículo!</p>
    <a class="btn btn-primary  font-weight-800 px-4"
       href="{{ urlContacto(cotizacion.datos.nombre, 'persona natural', cotizacion.datos.tipoVehiculo, idCotizacion) }}"
       target="blank">Contactar a un asesor</a>
  </div>
</div>
<div class="col-12 bg-white py-5">
  <div class="text-center mt-3 mt-md-4">
    <p class="h4 font-weight-800 py-4">Acreditado por las autoridades</p>
    <div class="d-flex flex-md-row flex-sm-column justify-content-around flex-wrap">
      <img class="mx-3 my-3"
           src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_ministerio%202.svg?alt=media&token=74f890e5-e8ca-41b8-a6e4-d601ff9030e7"
           alt="">
      <img class="mx-3 my-3"
           src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_sutran%202.svg?alt=media&token=fcdd9e42-910e-4bcc-9395-4b02d601960b"
           alt="">
      <img class="mx-3 my-3"
           src="https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/logo_osinergmin%201.svg?alt=media&token=0093ac0e-3878-4883-82a6-70e2794eaa7d"
           alt="">
    </div>
  </div>
</div>

