import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-acreditados',
  templateUrl: './acreditados.component.html',
  styleUrls: ['./acreditados.component.scss']
})
export class AcreditadosComponent implements OnInit {

  logosAcreditados;

  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.logosAcreditados = this.ds.logosAcreditados;
  }

}
