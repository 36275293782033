<div class="text-right">
    <button mat-icon-button (click)="cancelar()">
      <img src="assets/images/iconClose.svg">
    </button>
</div>
<div class="px-4">
    <div class="d-flex flex-column">
        <div *ngFor="let link of links" class="py-1">
            <a class="linkMenuMobile text-decoration-none py-1" [routerLink]="link.url" (click)="cancelar()">{{link.nombre}}</a>
            <div *ngFor="let submenu of link.submenu" class="pt-2">
                <a class="text-decoration-none py-1" [routerLink]="submenu.url" (click)="cancelar()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-corner-down-right" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#828282" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <path d="M6 6v6a3 3 0 0 0 3 3h10l-5 -5m0 10l5 -5" />
              </svg> {{submenu.nombre}}
                </a>
            </div>
        </div>
        <div class="mt-3">
            <a class="btn btn-primary btn-block btn-lg mb-2" routerLink="/cotizar" (click)="cancelar()">Cotizar</a>
            <!-- <a class="btn btn-primary btn-block btn-lg" routerLink="/cotizar" (click)="cancelar()">Cotizar</a> -->
            <a class="btn btn-outline-primary btn-block btn-lg" href="https://rastreo.orbitec.pe/login" target="blank">Iniciar sesión</a>
        </div>

    </div>

    <div class="text-muted mt-4 pr-4">
        <a class="text-reset" href="tel:17139149"><strong>T.</strong> (511) 713 9149</a><br>
        <a class="text-reset" href="tel:994579506"><strong>T.</strong> 994579506</a><br>
        <a class="text-reset" href="mailto:info@orbitec.pe"><strong>T.</strong> info@orbitec.pe</a><br>
        <a class="text-reset small" href="https://goo.gl/maps/n7bB8saGJhj8x2979">Cal. Germán Schereiber Nro. 276 Urb. Santa Ana - San Isidro</a>
    </div>
</div>
