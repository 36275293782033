<div class="backBannerRastreo">
    <div class="col-md-10 mx-auto">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                <li class="breadcrumb-item"><a routerLink="/servicios">Servicios</a></li>
                <li class="breadcrumb-item active">Rastreo logístico</li>
            </ol>
        </nav>
        <div class="text-center">
            <h1 class="text-primary mb-4">Rastreo logístico</h1>
        </div>
        <div class="row">
            <div class="col-md-6">
                <h3 class="text-secondary mt-5">
                    Mediante nuestro sistema de localización remota, en tiempo real, puedes conocer lo que sucede con tu vehículo las 24 horas del día.
                </h3>
                <div class="p-3 col-md-10 cajaBanner my-5">
                    <div class="row">
                        <img src="./assets/images/iconStatistic.svg" alt="" class="col-3">
                        <p class="text-primary col-9 pt-2">Nuestro servicio de rastreo logístico te permite optimizar el uso de su flota y reducir costos innecesarios.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/laptopComposicion.png?alt=media&token=9e22e1a6-d6dd-4766-8ffb-96059cdaa0b3" alt="" width="100%">
            </div>
        </div>
    </div>
</div>

<div class="col-md-10 mx-auto my-5">

    <h2 id="monitoreo" class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Plataforma de monitoreo</h2>
    <hr>
    <h4>A través de nuestra plataforma usted podrá monitorear y rastrear su flota en tiempo real de una manera muy eficiente.</h4>
    <div class="row">
        <div class="col-md-6 my-3 px-4" *ngFor="let monitoreo of monitoreos">
            <div class="cajaAlcances py-2">
                <h5 class="font-weight-bold text-secondary">
                    <span class="mr-3"><img class="cajaAccesoriosAdicionales rounded-circle p-2" src="{{monitoreo.image}}" width="60"></span> {{monitoreo.title}}
                </h5>
            </div>
            <p class="mt-3">{{monitoreo.description}}</p>
        </div>
    </div>

    <h2 id="alertas" class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Alertas de seguridad</h2>
    <hr>
    <div class="row">
        <div class="col-md-6 my-3 px-4" *ngFor="let alerta of alertas">
            <div class="cajaAlcances py-2">
                <h5 class="font-weight-bold text-secondary">
                    <span class="mr-3"><img class="cajaAccesoriosAdicionales rounded-circle p-2" src="{{alerta.image}}" width="60"></span> {{alerta.title}}
                </h5>
            </div>
            <p class="mt-3">{{alerta.description}}</p>
        </div>
    </div>

    <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Accesorios adicionales</h2>
    <hr>

    <div class="row mt-4">

        <div class="col-md-6" *ngFor="let accesorio of accesorios">
            <div class="row d-flex align-items-center" style="height: 80px">
                <div class="col-1">
                    <span><img src="./assets/images/vinetaOutline.svg" width="20"></span>
                </div>
                <div class="col-2 cajaAccesoriosAdicionales d-flex align-items-center justify-content-center mx-2" style="height: 70px">
                    <span><img src="{{accesorio.image}}" width="46"></span>
                </div>
                <div class="col-8 cajaAccesoriosAdicionales d-flex align-items-center" style="height: 70px">
                    <span class="pl-3 text-secondary font-weight-bold">{{accesorio.title}}</span>
                </div>
            </div>
            <div class="row d-flex justify-content-end px-3">
                <div class="col-9">
                    <p>{{accesorio.description}}</p>
                </div>

            </div>

        </div>

    </div>


    <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Telemetría</h2>
    <hr>
    <div class="row mt-4">
        <div class="col-md-6">
            <p>La telemetría te permite monitorear datos obtenidos desde el computador del vehículo a distancia y enviar información hacia un sistema de notificaciones para que puedas medir el desempeño de tu flota para identificar fallas o averías y corregir
                errores de conducción. <br><br> Ahorra, optimiza los recursos y genera rentabilidad para tu negocio.</p>
            <div class="row mt-4 text-secondary">
                <div class="col-md-6">
                    <ul class="list">
                        <li>Combustible utilizado</li>
                        <li>Revoluciones por minuto (RPM)</li>
                        <li>Odómetro</li>
                        <li>Temperatura</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul class="list">
                        <li>Distancia recorrida</li>
                        <li>Total de horas en trabajo</li>
                        <li>Porcentaje de combustible</li>
                        <li>Velocidad de vehículo</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <img src="./assets/images/ilustracionTelemetria.svg" width="100%">
        </div>
    </div>

    <h2 id="centralAlarmas" class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Central de alarmas</h2>
    <hr>

    <div class="bgCentralAlarmas rounded mt-4">
        <div class="col-md-5 py-7 ml-2 ml-md-5">
            <h4 class="text-secondary">ORBITEC cuenta con una central de monitoreo y personal especializado que labora las 24 horas del día los 365 días del año.</h4>
        </div>
    </div>

    <div class="row mt-5 mb-7">
        <div class="col-md-4 my-3">
            <div class="row">
                <div class="col-3">
                    <img src="./assets/images/centralAlarmasPnp.svg" alt="Orbitec Policía Nacional" width="100%">
                </div>
                <div class="col-9">
                    <p>ORBITEC a través de un convenio directo con la Policía Nacional brinda el apoyo para la recuperación de vehículos robados.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-3">
            <div class="row">
                <div class="col-3">
                    <img src="./assets/images/centralAlarmasEmergencia.svg" alt="Orbitec Policía Nacional" width="100%">
                </div>
                <div class="col-9">
                    <p>Comunicando las principales alarmas de emergencia que son activadas desde las unidades móviles y así actuar de manera rápida y conjunta en caso de accidentes, robos, secuestros y asaltos.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-3">
            <div class="row">
                <div class="col-3">
                    <img src="./assets/images/centralAlarmasMonitoreo.svg" alt="Orbitec Policía Nacional" width="100%">
                </div>
                <div class="col-9">
                    <p>Nuestro centro de monitoreo cuenta con el conocimiento y experiencia necesaria para cumplir cada requerimiento específico.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center my-7">
        <p>¿Necesitas mayor información?</p>
        <a class="btn btn-primary button px-4 mb-7" routerLink="/contacto">Cuéntanos tu requerimiento <img height="16" class="my-2 ml-1" src="./assets/images/arrowRight.svg"></a>
    </div>

</div>