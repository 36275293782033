<div class="col-md-10 mx-auto mb-6">

  <h2 class="text-secondary mt-6 title-policies"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span> Políticas de protección de datos </h2>
  <hr>

  <div class="col policies-container mt-4">
    <!-- text -->
    <p>
      Dentro del contexto de la Ley N° 29733 (en adelante, la Ley) y el Decreto Supremo N° 003-2013-JUS (en adelante, el Reglamento) , normas que se refieren a la protección de datos personales, el usuario autoriza, ya sea, mediante documento escrito con firma autógrafa, huella dactilar u otro autorizado por el ordenamiento jurídico que pueda ser impreso en una superficie de papel o mediante aceptación expresa automatizada (ej. “hacer clic” es una manifestación escrita válida a través de un medio digital) de los términos y condiciones, el tratamiento de los datos personales que le proporcione a ORBITEC S.A.C. (en adelante ORBITEC), por cualquier medio físico o electrónico, de acuerdo a los términos que se establecen en este documento y que se detallan a continuación:
    </p>
    <!-- list policies -->
    <ol class="list-policies">
      <!-- number 1 -->
      <li>
        <span>1.</span>
        ORBITEC, con domicilio en Cal. Germán Schereiber Nro. 276 Urb. Santa Ana - San Isidro, será responsable del uso, tratamiento y seguridad de los datos personales que yo le proporcione o que éste recopile, los que estarán almacenados en un banco de datos de su titularidad y que serán gestionados y tratados con todas las medidas de seguridad y confidencialidad por cualquiera de sus oficinas, la cual conservará los datos personales mientras sean necesarios para cumplir con las finalidades por las cuales se recopilaron o hasta que sean modificados, dependiendo de la naturaleza de los mismos, con la finalidad de utilizarlos en gestiones comerciales y administrativas.
        <br> <br>
        ORBITEC se obliga limitar el acceso a los datos personales a los que tienen acceso en virtud de la suscripción y/o ejecución del presente acuerdo, al personal estrictamente necesario para cumplir con la prestación de servicios a su cargo, comprometiéndose a suscribir con sus empleados y terceros de quienes se valga para el cumplimiento de sus obligaciones derivadas del presente acuerdo, un convenio de confidencialidad que disponga cuando menos, las mismas obligaciones de confidencialidad que las que son de su cargo.
      </li>
      <!-- number 2 -->
      <li>
        <span>2.</span>
        Finalidad del tratamiento de datos personales. Mis datos personales serán utilizados a través de canales presenciales y no presenciales con las siguientes finalidades: i) se me informe a través de cualquier medio de comunicación física o electrónica (teléfono, correo electrónico, medios telemáticos, aplicativos de mensajes instantáneos, SMS, redes sociales, páginas web o medios similares creados o por crearse) cualquier hecho o evento referido a la relación comercial establecida entre las partes, tales como bienes o servicios vendidos, precio de venta, condiciones de venta, instalaciones, plazos y datos similares; ii) se me envíe, a través de cualquier medio de comunicación física o electrónica (teléfono, correo electrónico, medios telemáticos, aplicativos de mensajes instantáneos, SMS, redes sociales, páginas web o medios similares creados o por crearse) publicidad, obsequios e información sobre los diferentes productos que provee ORBITEC iii) se realicen actividades de mercadeo (informes comerciales, estadísticas, encuestas y estudios de mercado); iv) se verifique que la información que se proporcione sea correcta, verdadera y se encuentre actualizada. ORBITEC podrá dar tratamiento a mis datos personales de manera directa o a través de proveedores de servicio que serán considerados como encargados de tratamiento de mis datos personales.
      </li>
      <!-- number 3 -->
      <li>
        <span>3.</span>
        Transferencia de mis datos personales a terceros: Reconozco que existe la posibilidad de que ORBITEC comparta, ceda o transfiera mis datos a terceros, con la finalidad de la realización de actividades comerciales o de mercadeo bajo la garantía de que ORBITEC procurará que no se de a mis datos un uso indebido. Esta transferencia suele darse con motivos netamente comerciales con personas y empresas terceras a ORBITEC y al giro de su negocio, tales como representantes de ventas, empresas aseguradoras, financieras, comercializadoras de vehículos, “encontrándose listadas al final del presente documento”. La transferencia no tiene porqué ocasionarme perjuicio alguno, no obstante, lo cual queda a salvo mi derecho de, con o sin expresión de causa, oponerme a que se den a conocer mis datos a cualquier tercero con o sin vinculación a ORBITEC.
        <br> <br>
        En virtud de ello ORBITEC deberá adoptar las medidas de seguridad de índole legal, técnica y organizativa necesarias para garantizar la seguridad de los Datos Personales y evitar su alteración, pérdida, sustracción, tratamiento o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos personales materia del tratamiento por encargo y los riesgos a que estén expuestos, ya provengan de la acción humana o del medio físico o natural.
        <br> <br>
        Para dicho efecto, ORBITEC declara expresamente que tiene implementados los controles de seguridad exigidos por la Ley de Protección de Datos Personales y su Reglamento.
      </li>
      <!-- number 4 -->
      <li>
        <span>4.</span>
        En caso ORBITEC le diera a mis datos personales un uso distinto a los señalados, lo comunicará a través del siguiente correo info@orbitec.pe, de manera que pueda oponerme a dichos tratamientos en caso de no estar de acuerdo.
      </li>
      <!-- number 5 -->
      <li>
        <span>5.</span>
        ORBITEC tratará los datos personales que yo le proporcione o a los que pueda acceder de forma física, oral o electrónica, a través de fuentes accesibles al público o de terceros y/o entidades de consulta de bases de datos domiciliadas en Perú o en el exterior, sean personas naturales o jurídicas, privadas o públicas.
      </li>
      <!-- number 6 -->
      <li>
        <span>6.</span>
        La negativa en la entrega de mis datos personales, así como la revocatoria del consentimiento otorgado sobre el uso de mis datos personales, imposibilita a ORBITEC incluirlos en su base de datos que remite información instantánea y actualizada. Además, de solicitar la revocatoria, ORBITEC me retirará de la lista de sus suscriptores, por lo que no podrá recibir información o comunicaciones sobre los diferentes productos, encuestas de satisfacción y mejora del servicio u eventos organizados por ORBITEC o cualquiera de sus dependencias.
      </li>
      <!-- number 7 -->
      <li>
        <span>7.</span>
        Usted podrá ejercitar sus derechos de Información, Acceso, Rectificación, Cancelación, Revocación, Oposición, etc. (ARCO) al uso de sus datos personales, de conformidad con la Ley de Protección de Datos Personales, Ley N° 29733. En ese sentido, Usted tiene derecho, entre otros, a acceder a su información personal, a solicitar la rectificación de datos inexactos y a revocar su consentimiento para el tratamiento de la misma; asimismo, podrá solicitar la supresión de sus datos u oponerse al tratamiento de los mismos, incluso cuando estos ya no resulten necesarios para los fines que motivaron su recopilación.
        <br> <br>
        El ejercicio de estos derechos es gratuito. Para ello, puede dirigirse por correo electrónico a info@orbitec.pe. o mediante un escrito dirigido a ORBITEC a la siguiente dirección física: Cal. Germán Schereiber Nro. 276 Urb. Santa Ana - San Isidro- Perú, adjuntando una copia del documento de identidad (DNI/CE/Pasaporte) que acredite su titularidad sobre los datos personales respecto de los cuales ejercerá su derecho. La atención de la solicitud será efectuada de acuerdo a los plazos previstos en la normatividad de protección de datos personales.
      </li>
      <!-- number 8 -->
      <li>
        <span>8.</span>
        Para el caso de uso de datos sensibles (datos personales constituidos por los datos biométricos que por sí mismos pueden identificar al titular, datos referidos al origen racial y étnico, ingresos económicos, opiniones o convicciones políticas, religiosas, filosóficas o morales, afiliación sindical e información relacionada a la salud o a la vida sexual), ORBITEC requerirá mi consentimiento expreso y por escrito, sin el cual no podrá utilizarlos.
      </li>
      <!-- number 9 -->
      <li>
        <span>9.</span>
        Podemos recopilar la información de su ubicación a través de las coordenadas GPS (por ejemplo, latitud / longitud) disponibles a través de su dispositivo móvil a través de nuestros aplicativos si los tiene instalados. También podemos recopilar información sobre su ubicación utilizando otras tecnologías y sensores que pueden proporcionarnos dicha información. Toda información que obtenemos como la ubicación de GPS, etc., que son capturados por nuestros sistemas son almacenadas de forma segura en nuestros servidores y se utiliza exclusivamente para brindar información desde nuestras aplicaciones.
        <br> <br>
        Uso de la información explícitamente en las funcionalidades propias de las aplicaciones y en la información resultante para el propio usuario o de acuerdo a como lo haya autorizado el usuario. No usamos esta información para compartirla con terceros ni darle uso comercial. Usamos la información para i) rastrear su ubicación y movimientos; ii) monitorear métricas agregadas con el objetivo de que su dueño pueda analizar dicha información; iii) diagnosticar y solucionar problemas asociados al servicio.
        <br> <br>
        Cualquier tema no tratado específicamente en este documento, se rige por la Ley y el Reglamento.
      </li>
      <!-- number 10 -->
      <li>
        <span>10.</span>
        Una vez finalizada la relación contractual, salvo que, por disposiciones legales, contables o financieras no sea factible, ORBITEC se obliga a destruir los datos personales en su totalidad, sin conservar ninguna copia, teniendo en cuenta los distintos soportes o documentos donde estos puedan constar: bases de datos en discos, bases de datos temporales, copias de seguridad, soportes en papel, o cualquier otro que pudiere haberse generado para la ejecución del presente acuerdo.
      </li>
      <!-- number 11 -->
      <li>
        <span>11.</span>
        ORBITEC se reserva el derecho de modificar y/o actualizar la presente Política de Privacidad como parte de su mejora continua, ya sea para adaptarla a futuros cambios normativos, brindar una mejor calidad de servicio o comunicar nuevas alternativas relacionadas a la presente Política.
        <br> <br>
        Usted será notificado con los cambios que se introduzcan a esta Política de Privacidad publicando en este Sitio la versión actualizada o modificada de la misma. Cuando usted utilice este Sitio, la Aplicación de ORBITEC u otros medios donde tome conocimiento de la presente Política de Privacidad después de tales cambios, estará consintiendo que los datos personales que se obtengan de usted o acerca de usted después de publicada la revisión de la Política de Privacidad, estén sujetos a la Política de Privacidad revisada.
        <br> <br>
        Si usted tiene dudas o preguntas sobre las políticas de privacidad o de procesos de datos de ORBITEC, por favor utilizar la dirección de correo electrónico a: info@orbitec.pe para contactarlos.
      </li>
    </ol>
  </div>

</div>
