<div class="backBannerSoftware">
  <div class="col-md-10 mx-auto">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb small">
        <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
        <li class="breadcrumb-item"><a routerLink="/servicios">Servicios</a></li>
        <li class="breadcrumb-item active">Desarrollo de software distribución</li>
      </ol>
    </nav>
    <div class="text-center">
      <h1 class="text-primary mb-4">Desarrollo de software distribución</h1>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-md-5">
        <h3 class="text-secondary mt-5">
            Tomamos en cuenta tus requerimientos y necesidades para transformarlos en un sistema de rastreo personalizado.
        </h3>
        <!-- <div class="p-3 col-md-10 cajaBanner my-5">
          <div class="row">
            <img src="./assets/images/iconStatistic.svg" alt="" class="col-3">
            <p class="text-primary col-9 pt-2">Nuestro servicio de rastreo logístico te  permite optimizar el uso de su flota y reducir costos innecesarios.</p>
          </div>
        </div> -->
      </div>
      <div class="col-md-6">
        <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/phoneComposer.png?alt=media&token=452ef007-080e-4e80-b798-7b04648d1801" alt="" width="100%">
      </div>
    </div> 
  </div>
</div>

<div class="col-md-10 mx-auto my-5">
  <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Reportes</h2>
  <hr>
  
  <div class="row">
    <div class="col-md-6 my-4 px-5">
      <img src="/assets/images/reportesInformesHistoricos.svg" alt="Reportes Orbitec">
      <h4 class="text-secondary font-weight-bold my-4">Informes históricos de flota, automáticos y programables con envío por e-mail:</h4>
      <ul class="list">
        <li>Reporte de hoja de ruta realizada.</li>
        <li>Reporte de eventos relevantes (aperturas de puerta, ingreso a zona no permitida, motor encendido, etc.).</li>
        <li>Registro de excesos de velocidad, frenadas bruscas, clientes visitados, etc.</li>
        <li>Simulación de los viajes realizados sobre el mapa.</li>
        <li>Registros históricos total de kilómetros recorridos, excesos de velocidad, etc.., almacenados en nuestros servidores.</li>
      </ul>
    </div>
    <div class="col-md-6 my-4 px-5">
      <img src="assets/images/reportesPersonalizacion.svg" alt="Reportes Orbitec">
      <h4 class="text-secondary font-weight-bold my-4">Todos nuestros reportes pueden ser personalizados por el usuario:</h4>
      <ul class="list">
        <li>Por ejemplo, nuestro sistema le da la posibilidad de recibir  a última hora del día un detalle de los lugares en que el móvil estuvo detenido.</li>
        <li>Recibir una vez por semana el detalle de los excesos de velocidad y el primer día de cada mes, recibir un mail con el total de los kms recorridos.</li>
      </ul>
    </div>
  </div>

  <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Logística</h2>
  <hr>

  <div class="row text-secondary my-5">
    <div class="col-md-6" *ngFor="let logistic of logistics">
      <div class="row cajaLogistica py-4 px-3 m-3">
        <div class="col-3">
          <img src="{{logistic.image}}" alt="Logística creación de puntos" width="100%">
        </div>
        <div class="col-9 d-flex align-items-center">
          <h5>{{logistic.description}}</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center my-7">
    <p>¿Necesitas mayor información?</p>
    <a class="btn btn-primary button px-4 mb-7" routerLink="/contacto">Cuéntanos tu requerimiento <img height="16" class="my-2 ml-1" src="./assets/images/arrowRight.svg"></a>
  </div>

</div>