import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FirebaseService} from 'src/app/services/firebase.service';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-gracias-cotizador-empresa',
  templateUrl: './gracias-cotizador-empresa.component.html',
  styleUrls: ['./gracias-cotizador-empresa.component.scss']
})
export class GraciasCotizadorEmpresaComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center: true,
    startPosition: 1,
    mergeFit: true,
    navSpeed: 700,
    navText: ['', '', ''],
    responsive: {
      0: {
        items: 1
      },
      300: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 2
      }
    },
    nav: true
  };
  idCotizacion;
  cotizacion;

  planes = [
    {
      id: 1,
      costo: 85,
      tipo: 'Mensual',
      renta: 45,
      ahorro: false,
      valor_ahorro: 0,
      iz: false,
      der: true,
      porcentaje_ahorro: 0,
      urlimg: 'https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/1.svg?alt=media&token=fee9d914-f636-4b26-b54a-48de154a74a3'
    },
    {
      id: 2,
      costo: 85,
      tipo: 'Semestral',
      renta: 225,
      ahorro: true,
      iz: true,
      der: true,
      valor_ahorro: 45,
      porcentaje_ahorro: 15,
      urlimg: 'https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/6.svg?alt=media&token=c5450106-a55a-4e3e-8853-53dc8e2d34b3'
    },
    {
      id: 3,
      costo: 85,
      tipo: 'Anual',
      renta: 432,
      ahorro: true,
      iz: true,
      der: false,
      valor_ahorro: 108,
      porcentaje_ahorro: 20,
      urlimg: 'https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/calendar_vector.svg?alt=media&token=20acd516-3bb2-42be-a0c6-15352df8a480'
    }
  ];

  urlContacto = (nombre, tipo, vehiculo, cotizacion) => {
    // return 'https://api.whatsapp.com/send?phone=51944121891&text=Hola soy ' + nombre + ', me interesa el Plan de Servicio ' + tipo + ' para mi compañía con vehículos tipo ' + vehiculo + '. Mi ID es: ' + cotizacion;
    return 'https://orbitec.pe';
  }

  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idCotizacion = params.id;

      this.fs.getCotizacionEmpresa(this.idCotizacion).subscribe(res => {
        this.cotizacion = res;
        console.log(JSON.stringify(this.cotizacion));
      });
    });
  }

  changeCarousel(value) {
    console.log(value);
    this.customOptions.startPosition = value;
  }

}
