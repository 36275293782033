<div class="col-md-11 mx-auto py-4 py-md-6">
    <div class="text-center">
        <p class="h5 mb-3 mb-md-4">Bienvenido al administrador de Orbitec</p>
    </div>
    <div class="grillaSeccionesAdmin">
        <div class="border rounded-lg p-3 p-md-4 position-relative text-center" *ngFor="let seccion of secciones">
            <p class="h4 text-secondary mb-3 mb-md-4">{{seccion.nombre}}</p>
            <a class="btn btn-primary stretched-link px-3" [routerLink]="seccion.url">Ir a sección</a>
        </div>
    </div>
</div>