<div class="container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb small">
          <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
          <li class="breadcrumb-item active">Servicios</li>
        </ol>
    </nav>
    <div class="text-center">
        <p class="text-primary mb-4 h1">Servicios</p>
      </div>
    <div class="row pt-3 pt-md-5 pb-6 pb-md-9">
        <div class="col-md-6 py-2">
            <a class="btn btn-lg btn-block btn-primary" routerLink="/servicios/rastreoLogistico">Rastreo Logístico</a>
        </div>
        <div class="col-md-6 py-2">
            <a class="btn btn-lg btn-block btn-primary" routerLink="/servicios/desarrolloSoftware">Desarrollo de Software</a>
        </div>
    </div>
</div>