import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  links = [
    { nombre: 'Inicio', url: '/' },
    { nombre: 'Servicios', url: '/servicios',
      submenu: [
        { nombre: 'Rastreo Logístico', url: '/servicios/rastreoLogistico' },
        { nombre: 'Desarrollo de Software', url: '/servicios/desarrolloSoftware' },

      ]
    },
    { nombre: 'Nosotros', url: '/nosotros' },
    { nombre: 'Contacto', url: '/contacto' },
  ];

  banners = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/imagesBanner%2Fbanner1.jpg?alt=media&token=30edddeb-bbcd-4fa8-969e-8720eedd9f78',
      titulo: 'Que tu negocio no se detenga',
      descripcion: 'Mantén el control de tu flota en cualquier momento y lugar',
      textoBoton: 'Rastreo logístico',
      urlBoton: 'servicios/rastreoLogistico'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/imagesBanner%2Fbanner2.jpg?alt=media&token=3adc1108-3924-40ee-b4c0-7653dc44125b',
      titulo: 'Conoce el camino',
      descripcion: 'Para alcanzar tus objetivos',
      textoBoton: 'Desarrollo de Software',
      urlBoton: 'servicios/desarrolloSoftware'
    }
  ];

  clientes = [
    'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logosClientes%2FlogoCocaColaOn.png?alt=media&token=dbf3ee26-b0a1-4ac5-8583-5fde54b9c073',
    'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logosClientes%2FlogoKimberlyClarkOn.png?alt=media&token=8966e35d-f53b-45ec-b9b6-b69bcc714dc3',
    'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logosClientes%2FlogoSodimacOn.png?alt=media&token=7a810ae7-e4de-4b91-b0e9-85cc6a4d9e3b',
    'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logosClientes%2FlogoTransporteEstrategicoOn.png?alt=media&token=ea2a1baa-4004-4f94-80df-f799a2560bc9'
  ];

  datos = [
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/iconsDatos%2F01.svg?alt=media&token=9d9927db-b4e7-474f-8d5e-408169e25e78',
      titulo: 'Reduce el costo de combustible y mantenimiento',
      descripcion: 'Analiza y optimiza tus rutas, reduce el gasto de combustible y mantenimiento con reportes a tu medida.'
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/iconsDatos%2F02.svg?alt=media&token=465f5e54-27a3-475f-9c5e-06e5a3e330ad',
      titulo: 'Elimina o reduce el tiempo excesivo',
      descripcion: 'Maneja tu flota eficientemente, con nuestros productos podrás reducir tiempos muertos y costos de horas hombre.'
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/iconsDatos%2F03.svg?alt=media&token=12c32c25-7241-4af3-a61c-ebc660db1962',
      titulo: 'Mejora la productividad y efectividad de tu flota',
      descripcion: 'Calcula los tiempos de viaje y las tareas terminadas en tiempo real para mejorar la productividad de tu flota.'
    },
    {
      icon: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/iconsDatos%2F04.svg?alt=media&token=978f73ef-bfb0-4f81-9075-126c2b502bef',
      titulo: 'Enriquece tu servicio al cliente',
      descripcion: 'Mejora tu servicio al cliente y brinda estimaciones acertadas de entrega o recepción de mercancía.'
    },
  ];

  servicios = [

      {
        image: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/softwareRastreo.jpg?alt=media&token=f655c1bc-86b1-49d2-a130-70fecc25e432',
        title: 'Software de rastreo',
        description: 'Lorem ipsum is simply dummie text for industries graphics.',
        url: '/servicios/rastreoLogistico',
        fragment: ''
      },

      {
        image: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/centralAlarmas.jpg?alt=media&token=ded2910f-6550-497f-ab69-09e133e9045c',
        title: 'Central de alarmas',
        description: 'Lorem ipsum is simply dummie text for industries graphics.',
        url: '/servicios/rastreoLogistico',
        fragment: 'centralAlarmas'
      },

      {
        image: 'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/servicio247.jpg?alt=media&token=0c0434e3-fa7e-4ef7-a43a-68eed829c4e7',
        title: 'Servicio 24/7',
        description: 'Lorem ipsum is simply dummie text for industries graphics.',
        url: '/servicios/rastreoLogistico',
        fragment: ''
      },
    ];

    logosAcreditados = [
      'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_ministerio.png?alt=media&token=95668f3a-4ef9-4806-98e9-e94556108a1e',
      'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_sutran.png?alt=media&token=60acbda9-f67f-4c4c-b21a-53790482f0b7',
      'https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_osinergmin.jpg?alt=media&token=1f4a88e7-03c9-4228-9b73-302f7178ad7b'
    ];

  constructor() { }
}
