import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss']
})
export class CotizarComponent implements OnInit {

  tipos = [
    {
      imgUrl: 'https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/web_img_1.svg?alt=media&token=b5ac8b18-d908-4b25-b6d4-030057749b32',
      titulo: 'Vehículo Particular',
      url: '/cotizar/personaNatural'
    },
    {
      imgUrl: 'https://firebasestorage.googleapis.com/v0/b/orbitecweb.appspot.com/o/web_img_2.svg?alt=media&token=f8132ef9-9578-411a-a7aa-1aaaa96a3b8c',
      titulo: 'Compañía Logística',
      url: '/cotizar/empresa'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
