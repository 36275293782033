<div class="backBannerNosotros">
    <div class="col-md-10 mx-auto">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
            </ol>
        </nav>
        <div class="text-center">
            <h1 class="text-primary mb-4">Gestión y tecnología satelital</h1>
        </div>
        <div class="row d-flex justify-content-between">
            <div class="col-md-5">
                <h3 class="text-secondary mt-5">
                    Desarrollamos productos personalizados, enfocados en cada una de las necesidades de control y gestión de nuestros clientes.
                </h3>
                <div class="p-3 col-md-10 cajaBanner my-5">
                    <div class="row">
                        <img src="./assets/images/iconSolucionesTecnologicas.svg" alt="" class="col-3">
                        <p class="text-primary col-9 pt-2">De esta manera brindamos soluciones tecnológicas con productos de calidad para todas las industrias del mercado.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 px-5">
                <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/orbitecTruck.png?alt=media&token=4825993e-79c2-493c-b11e-9d053fa16deb" alt="" width="100%">
            </div>
        </div>
    </div>
</div>

<div class="col-md-10 mx-auto">

    <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Industrias</h2>
    <hr>

    <div class="row my-5 align-items-center justify-content-between">
        <div class="col-md-5">
            <h4 class="font-weight-bold text-primary mb-3">Tecnología a la medida de tu negocio</h4>
            <h5 class="text-secondary">Desarrollamos productos personalizados que permiten a nuestros clientes alcanzar objetivos rentables y sobre todo, mantener el control de sus operaciones de logística en cualquier momento y lugar.</h5>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-4 text-center my-3" *ngFor="let industria of industrias">
                    <img src="{{industria.image}}" width="80px">
                    <p class="text-secondary">{{industria.description}}</p>
                </div>
            </div>
        </div>
    </div>

    <h2 class="text-secondary mt-6"><span><img src="./assets/images/vinetaSolid.svg" width="32" class="mr-4"></span>Homologación</h2>
    <hr>

    <div class="row my-5 align-items-center justify-content-between">
        <div class="col-md-5">
            <h4 class="font-weight-bold text-primary mb-3">Acreditados por las autoridades</h4>
            <h5 class="text-secondary">Orbitec cuenta con las credenciales y permisos para el desarrollo de sus operaciones, así como con equipos homologados por el Ministerio de Transporte y Comunicaciones. <br><br>Por ello, realizamos la transmisión de datos de forma ininterrumpida
                durante las 24 horas del día a las autoridades solicitantes como Sutran y Osinergmin.</h5>
        </div>
        <div class="col-md-5">

            <div class="text-center">
                <div class="cajaHomologacion">
                    <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_ministerio.png?alt=media&token=95668f3a-4ef9-4806-98e9-e94556108a1e" alt="Orbitec Ministerio de transportes y comunicaciones" height="50px">
                </div>
                <div class="cajaHomologacion ml-auto">
                    <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_sutran.png?alt=media&token=60acbda9-f67f-4c4c-b21a-53790482f0b7" alt="Orbitec Ministerio de transportes y comunicaciones" height="50px">
                </div>
                <div class="cajaHomologacion">
                    <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/logo_osinergmin.jpg?alt=media&token=1f4a88e7-03c9-4228-9b73-302f7178ad7b" alt="Orbitec Ministerio de transportes y comunicaciones" height="50px">
                </div>
            </div>

        </div>
    </div>

    <div class="text-center my-7">
        <p>¿Necesitas mayor información?</p>
        <a class="btn btn-primary button px-4 mb-7" routerLink="/contacto">Cuéntanos tu requerimiento <img height="16" class="my-2 ml-1" src="./assets/images/arrowRight.svg"></a>
    </div>

</div>