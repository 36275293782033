import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {

   links = [
    { nombre: 'Inicio', url: '/' },
    { nombre: 'Servicios', url: '/servicios',
      submenu: [
        { nombre: 'Rastreo Logístico', url: '/servicios/rastreoLogistico' },
        { nombre: 'Desarrollo de Software', url: '/servicios/desarrolloSoftware' },

      ]
    },
    { nombre: 'Nosotros', url: '/nosotros' },
    { nombre: 'Contacto', url: '/contacto' },
  ];

  constructor(
    public dialogRef: MatDialogRef<MenuMobileComponent>,
  ) { }

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close();
  }

}
