import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarCotizacionEmpresaComponent } from './eliminar-cotizacion-empresa/eliminar-cotizacion-empresa.component';

@Component({
  selector: 'app-cotizaciones-empresa',
  templateUrl: './cotizaciones-empresa.component.html',
  styleUrls: ['./cotizaciones-empresa.component.scss']
})
export class CotizacionesEmpresaComponent implements AfterViewInit {

  count: number;
  loading: boolean;
  whatsApp: string;
  value;

  displayedColumns = [ 'datos.fechaCreacion', 'id', 'datos.nombre', 'datos.email', 'datos.celular', 'datos.ruc',  'datos.tipoVehiculo', 'cotizacion', 'mas'];
  cotizacionesEmpresaData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.cotizacionesEmpresaData.filter = filterValue;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.cotizacionesEmpresaData.filter = filterValue.trim().toLowerCase();
  // }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog
  ) { }

  ngAfterViewInit(): void {

    this.fs.getAllCotizacionesEmpresa().subscribe(data => {
      this.cotizacionesEmpresaData.data = data;
      this.loading = false;
      this.count = data.length;
    });

    this.cotizacionesEmpresaData.paginator = this.paginator;
    this.cotizacionesEmpresaData.sort = this.sort;

    // Para ordenar objetos anidados
    this.cotizacionesEmpresaData.sortingDataAccessor = (item, property) => {
      // Split '.' to allow accessing property of nested object
      if (property.includes('.')) {
          const accessor = property.split('.');
          let value: any = item;
          accessor.forEach((a) => {
              value = value[a];
          });
          return value;
      }
      // Access as normal
      return item[property];
    };

    // Para filtrar objetos anidados, incluye minusculas
    this.cotizacionesEmpresaData.filterPredicate = (data: any, filter: string) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  clearFilters() {
    this.cotizacionesEmpresaData.filter = '';
    this.value = '';
  }


  openModalDelete(producto) {
    const dialogRef = this.dialog.open(EliminarCotizacionEmpresaComponent, {
      width: '340px',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

}
