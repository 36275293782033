<div class="bg-light-blue">
    <div class="bannerCotizador">
        <div class="col-sm-12 mx-auto d-flex justify-content-center h-100">
            <div class="text-white d-flex justify-content-center align-items-center">
                <div class="text-center">
                    <p class="titulo font-weight-700 mb-0">Cotiza Online al instante</p>
                    <p class="subtitulo mb-0">Selecciona una opción:</p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 mx-0 d-flex justify-content-center">
        <div class="col-md-12 col-lg-11 col-xl-9 mx-1 text-center pt-2 pt-md-3">
            <div class="grillaCotizador">
                <div *ngFor="let tipo of tipos" class="d-flex justify-content-center align-items-center flex-column my-4">
                    <img class="mb-4" src="{{tipo.imgUrl}}" alt="">
                    <a class="btn btn-primary font-weight-500 py-lg-2 py-sm-1 px-5 px-sm-3 text-uppercase "  [routerLink]="tipo.url">{{tipo.titulo}}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 mx-0 d-flex justify-content-center">
        <div class="col-md-12 col-lg-11 col-xl-9 mx-1 pt-2 pt-md-3">
            <div class="grillaCotizador align-items-start">
                <div class="col-sm-12 col-md-6 mb-3">
                    <img class="image" src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/plataformaMonitoreo.png?alt=media&token=5f500e02-a493-4a46-943b-927be3fb9f16" width="100%">
                </div>
                <div  class="col-sm-12 col-md-6 text">
                    <p class="h3 text-primary mb-3  font-weight-800"> Gestión y tecnología satelital</p>
                    <p class="text-secondary">Somos una empresa peruana especializada en la Gestión de Flota Vehicular y Telemetría. Desarrollamos productos personalizados, enfocados en cada una de las necesidades de control y gestión de nuestros clientes. </p>
                    <a class="font-weight-800" routerLink="/servicios/rastreoLogistico">Conoce más</a>
                </div>
            </div>
        </div>
    </div>
</div>
