<!-- <div class="backNavInterna" [ngClass]="getRoute()">
    <div class="col-md-10 mx-auto d-flex justify-content-between align-items-center">
        <a class="my-3 my-md-4" routerLink="/">
            <img class="logoOrbitec" src="assets/images/logoOrbitecWhite.svg" alt="Logo Canatur">
        </a>
        <div class="d-flex align-items-center">
            <div class="d-none d-md-block">
                <div class="navbar navbar-expand-lg navbar-dark">
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item ml-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a class="nav-link" [routerLink]="['/']">Inicio</a>
                            </li>
                            <li class="nav-item dropdown ml-3" routerLinkActive="active">
                                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" href="" id="navbarDropdown" aria-haspopup="true" aria-expanded="false">Servicios </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" [routerLink]="['/servicios/rastreoLogistico']">Rastreo logístico</a>
                                    <a class="dropdown-item" [routerLink]="['/servicios/desarrolloSoftware']">Desarrollo de software</a>
                                </div>
                            </li>
                            <li class="nav-item ml-3" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/nosotros']">Nosotros</a>
                            </li>
                            <li class="nav-item ml-3" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
                            </li>
                        </ul>
                        <a class="btn btn-primary px-3 py-2 font-weight-bold rounded-pill ml-2" routerLink="/cotizar">
                            <div class="d-flex align-items-center">
                                <span>COTIZAR</span>
                                <span class="h4 mb-0 ml-2"><i class="fas fa-check-circle"></i></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="d-block d-md-none">
                <button mat-icon-button (click)="openModalMenuMobile()"><img src="assets/images/iconMenuMobile.svg"></button>
            </div>
        </div>
    </div>
</div> -->


<div class="bg-white">
    <div class="col-md-10 mx-auto d-flex justify-content-between align-items-center">
        <a class="my-2 my-md-2" routerLink="/">
            <img class="logoOrbitec" src="assets/images/logoOrbitec.svg" alt="Logo Orbitec">
        </a>
        <div class="d-flex align-items-center">
            <div class="d-none d-md-block">
                <div class="navbar navbar-expand-lg navbar-light">
                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item ml-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a class="nav-link" [routerLink]="['/']">Inicio</a>
                            </li>
                            <li class="nav-item dropdown ml-3" routerLinkActive="active">
                                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" href="" id="navbarDropdown" aria-haspopup="true" aria-expanded="false">Servicios </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" [routerLink]="['/servicios/rastreoLogistico']">Rastreo logístico</a>
                                    <a class="dropdown-item" [routerLink]="['/servicios/desarrolloSoftware']">Desarrollo de software</a>
                                </div>
                            </li>
                            <li class="nav-item ml-3" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/nosotros']">Nosotros</a>
                            </li>
                            <li class="nav-item ml-3" routerLinkActive="active">
                                <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
                            </li>
                        </ul>
                        <a class="btn btn-primary px-3 buttonSpecial rounded-pill mx-2" routerLink="/cotizar">
                            <div class="d-flex align-items-center">
                                <span>COTIZAR</span>
                                <span class="h5 mb-0 ml-2"><i class="fas fa-check-circle"></i></span>
                            </div>
                        </a>
                        <a class="btn btn-outline-primary  buttonSpecial rounded-pill" href="https://rastreo.orbitec.pe/login" target="blank">Iniciar sesión
                      </a>
                    </div>
                </div>
            </div>
            <div class="d-block d-md-none">
                <button mat-icon-button (click)="openModalMenuMobile()"><img src="assets/images/iconMenuMobile.svg"></button>
            </div>
        </div>
    </div>
</div>
