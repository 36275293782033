import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'orbitecApp';
  constructor(
    private router: Router
  ) {
    const navEndEvents$ = this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      );
    console.log('result ', navEndEvents$);
    navEndEvents$.subscribe((event: NavigationEnd) => {
      console.log('data del event', event);
      if (event.urlAfterRedirects.indexOf('gracias') !== -1) {
        // <!-- Event snippet for Registro de cotización conversion page
          gtag('event', 'conversion', {'send_to': 'AW-401500362/524PCMzCxvwBEMrRub8B'});
      }
      gtag('config', 'AW-401500362', {
        'page_path': event.urlAfterRedirects
      });
    });
  }
}
