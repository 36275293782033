import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios-plan-empresa',
  templateUrl: './beneficios-plan-empresa.component.html',
  styleUrls: ['./beneficios-plan-empresa.component.scss']
})
export class BeneficiosPlanEmpresaComponent implements OnInit {

  accesos = [
    'Acceso total al sistema web',
    'Acceso total al APP mobil',
    'Central de Emergencias 24x7x365',
    'Notificación de alertas y alarmas',
    'Recuperación de vehículos robados',
    'Flotas administrables',
    'Geo-cercas virtuales',
    'Multi usuarios a todo nivel',
    'Perfiles de conductor',
    'Reportes generales y personalizables',
    'Diversas cartografías',
    'Historial de datos (hasta 24 meses)',
    'Tracking para análisis de recorrido',
    'Integración con BD externos',
    'Soporte técnico 24x7',
    'Integración de diversos sensores'
  ];

  eventos = [
    'Motor encendido',
    'Motor apagado',
    'Aceleración brusca',
    'Frenado brusco',
    'Velocidad en curva',
    'Excesos de velocidad',
    'Detenido',
    'Estacionado',
    'Conduciendo',
    'Sobreparada',
    'Botón de pánico',
    'Batería desconectada',
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
