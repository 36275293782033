<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Acceso Web y App
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex align-items-center my-2" *ngFor="let acceso of accesos">
            <div class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#78B727" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
            </div>
            <div class="">
                <p class="mb-0">{{acceso}}</p>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Beneficios adicionales
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex align-items-center my-2" *ngFor="let adicional of adicionales">
            <div class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#78B727" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
            </div>
            <div class="">
                <p class="mb-0">{{adicional}}</p>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>