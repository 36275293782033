import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desarrollo-software',
  templateUrl: './desarrollo-software.component.html',
  styleUrls: ['./desarrollo-software.component.scss']
})
export class DesarrolloSoftwareComponent implements OnInit {

  logistics;

  constructor() { }
  
  ngOnInit(): void {
    this.logistics = [

      {
        image: './assets/images/logisticaCreacionPuntos.svg',
        description: 'Creación de puntos, zonas y Geocorredores, configurando la logística de acuerdo al vehículo, día y hora.'
      },

      {
        image: './assets/images/logisticaAlertas.svg',
        description: 'Todas las alertas son programables para que se generen en los distintos días de la semana y en los horarios que uno desee.'
      },

      {
        image: './assets/images/logisticaPuntosNoSeguros.svg',
        description: 'Los puntos y zonas pueden definirse como seguros o no seguros, como también públicos o no, para ser vistos o no por otros usuarios del sistema.'
      },

      {
        image: './assets/images/logisticaIncidencias.svg',
        description: 'Las incidencias generadas mediante la configuración del sistema de logística pueden accionar avisos automáticos de: “fuera de zona”, “zona prohibida”, “fuera de ruta”, etc.'
      },
    ]

  }

}
