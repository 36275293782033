import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service'
import * as firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  formContacto: FormGroup;
  @ViewChild('f') form;

  constructor(
    private fb: FormBuilder,
    private fs: FirebaseService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9)] ],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['', Validators.required],
      fechaCreacion: [ firebase.firestore.FieldValue.serverTimestamp() ],
    });
  }

  onSubmit() {
    if (this.formContacto.valid) {
      this.guardarContacto();
    } else {
      this.validateAllFormFields(this.formContacto);
    }
  }

  guardarContacto() {
    this.fs.addContacto(this.formContacto.value)
    .then(() => {
      console.log(this.formContacto.value);
      this.form.resetForm({
        fechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
      });
      this.snackBar.open('Tu mensaje ha sido enviado', 'CERRAR', {
        duration: 8000,
      });
    }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorNombre() {
    return this.formContacto.controls.nombre.hasError('required') ? 'Escriba sus nombres' : '';
  }

  errorCelular() {
    return this.formContacto.controls.celular.hasError('required') ? 'Ingrese un número de teléfono' :
    this.formContacto.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 caracteres' :
    this.formContacto.controls.celular.hasError('pattern') ? 'Solo se admiten números.' : '';
  }

  errorEmail() {
    return this.formContacto.controls.email.hasError('required') ? 'Ingrese un email' :
    this.formContacto.controls.email.hasError('email') ? 'No es un email válido' : '';
  }

  errorMensaje() {
    return this.formContacto.controls.mensaje.hasError('required') ? 'Describa su mensaje' : '';
  }

  


}
