<div class="">
    <div class="swiper-container myRoundedCorner" [swiper]="config">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let slide of banners">
                <div class="imagesBanner w-100 h-100" [style.background-image]="'url('+ slide.image +')'">
                    <div class="col-md-10 mx-auto w-100 h-100 d-flex justify-content-end align-items-center">
                        <div class="boxCaption">
                            <p class="titulo">{{slide.titulo}}</p>
                            <p class="descripcion">{{slide.descripcion}}</p>
                            <a class="btn btn-primary px-3" [routerLink]="slide.urlBoton">{{slide.textoBoton}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
        <!-- <div class="swiper-button-next d-none d-md-block"></div>
      <div class="swiper-button-prev d-none d-md-block"></div> -->
    </div>
</div>

<div class="col-md-10 mx-auto my-3 my-md-4">
    <div class="grillaLogosClientes">
        <div class="border rounded p-2 p-md-3" *ngFor="let cliente of clientes">
            <img [src]="cliente" alt="" width="100%">
        </div>
    </div>
</div>


<div class="backGeolocalizacion">
    <div class="col-md-10 mx-auto py-6">
        <h2 class="text-center mb-6 text-primary">Mayor precisión para una gestión eficiente</h2>
        <div class="row d-flex justify-content-between">
            <div class="col-md-8 mb-4">
                <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/camion.png?alt=media&token=d6d8d68a-aa4a-455c-bee6-fcb17f9f288b" width="100%" alt="Gestión vehicular eficiente">
            </div>
            <div class="col-md-3 text-center text-md-right">
                <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/ondas.svg?alt=media&token=7bc16c24-8f3e-47ed-b8d1-5e8e1026812d" width="100%" alt="indicadores en tiempo real">
                <p>Obtenga toda la información necesaria para administrar y controlar cada vehículo de su flota.</p>
                <a class="btn btn-primary px-3" routerLink="/servicios/rastreoLogistico">Conoce más</a>
            </div>
        </div>
    </div>
</div>

<div class="backDatos py-4 py-md-6">
    <div class="col-md-10 mx-auto">
        <p class="h2 text-center mb-4 text-white mb-4 mb-md-5">Toma decisiones sobre datos exactos</p>
        <div class="grillaDatos">
            <div class="d-flex flex-column flex-md-row text-center text-md-left" *ngFor="let dato of datos">
                <div class="mr-0 mr-4">
                    <img class="imgDato" [src]="dato.icon" alt="">
                </div>
                <div class="text-white my-2 my-md-0">
                    <p class="h4">{{dato.titulo}}</p>
                    <p class="textDatos">{{dato.descripcion}}</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="backMonitoreo">
    <div class="col-md-10 mx-auto py-6">
        <div class="row d-flex justify-content-between">
            <div class="col-md-6">
                <img src="https://firebasestorage.googleapis.com/v0/b/orbitec-ec337.appspot.com/o/plataformaMonitoreo.png?alt=media&token=5f500e02-a493-4a46-943b-927be3fb9f16" width="100%">
            </div>
            <div class="col-md-4 text-center text-md-right mt-5">
                <h1 class="text-primary">Plataforma de Monitoreo</h1>
                <p>Mantén el control de tu flota en cualquier momento y lugar.</p>
                <a class="btn btn-primary px-3" routerLink="/servicios/rastreoLogistico">Conoce más</a>
            </div>
        </div>
    </div>
</div>


<div class="backNosotros">
    <div class="col-md-10 mx-auto py-7">
        <div class="text-center">
            <h1 class="text-primary">No existen atajos para la seguridad</h1>
            <p class="lead text-secondary mt-4 mb-5 px-md-7 lineHeight1">Orbitec es una empresa peruana especializada en la Gestión de Flota Vehicular y Telemetría. Desarrollamos tecnología e innovamos con productos personalizados, enfocados en cada una de las necesidades de control y gestión de nuestros clientes.
            </p>
            <a class="btn btn-primary px-4 btn-lg" routerLink="/nosotros">Conoce más</a>
        </div>
    </div>
</div>


<div class="backServicios">
    <div class="col-md-10 mx-auto py-4 py-md-7">
        <div class="row  d-flex justify-content-around">

            <div class="col-md-3 px-3 text-center" *ngFor="let servicio of servicios">
                <img class="rounded-circle" src="{{servicio.image}}" width="100%" alt="">
                <h5 class="text-primary mt-4 font-weight-bold">{{servicio.title}}</h5>
                <!-- <p>{{servicio.description}}</p> -->
                <div>
                    <a class="btn btn-primary button px-3" [routerLink]="servicio.url">Conoce más</a>
                </div>
            </div>
        </div>
    </div>
</div>
