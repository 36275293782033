import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-cotizacion-empresa',
  templateUrl: './eliminar-cotizacion-empresa.component.html',
  styleUrls: ['./eliminar-cotizacion-empresa.component.scss']
})
export class EliminarCotizacionEmpresaComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private dialogRef: MatDialogRef<EliminarCotizacionEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  eliminarReserva(idCotizacion) {
    this.deshabilitado = true;
    this.fs.deleteCotizacionEmpresa(idCotizacion)
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Cotización eliminada', 'CERRAR', {
        duration: 3000,
      });
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

}
