import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PoliticaPrivacidadModalComponent } from '../politica-privacidad-modal/politica-privacidad-modal.component';
@Component({
  selector: 'app-persona-natural',
  templateUrl: './persona-natural.component.html',
  styleUrls: ['./persona-natural.component.scss']
})
export class PersonaNaturalComponent implements OnInit {

  formDatos: FormGroup;
  linear = true;

  vehiculos = [
    'Auto',
    'Camioneta',
    'Minivan',
    'Moto Lineal',
    'Mototaxi',
    'Otro'
  ];

  cotizacion;
  precio: number;
  nombre = '';
  deshabilitado: boolean;

  onlyText = '[a-zA-Z\ áéíóúÁÉÍÓÚñÑ]*';

  constructor(
    private fb: FormBuilder,
    private fs: FirebaseService,
    private afs: AngularFirestore,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.formDatos = this.fb.group({
      // nombre: ['', [Validators.required, Validators.pattern('[a-zA-Z\ áéíóúÁÉÍÓÚñÑ]*'), Validators.pattern(this.onlyLetras)]],
      nombre: ['', [Validators.required, Validators.pattern(this.onlyText)]],
      email: ['', [Validators.required, Validators.email]],
      celular: [ '', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      tipoVehiculo: ['', Validators.required],
      // whatsApp: [false],
      acepta: [false, Validators.requiredTrue],
      fechaCreacion: [firebase.firestore.Timestamp.fromDate(new Date())],
    });

    this.cotizacion = this.afs.collection('cotizacionesPersonaNatural').ref.doc();
    console.log(this.cotizacion.id);

  }


  onSubmit() {
    if (this.formDatos.valid) {
      this.guardarAfiliacion();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formDatos);
      this.deshabilitado = false;
    }
  }

  guardarAfiliacion() {
    // const cotizacion = this.afs.collection('cotizacionesPersonaNatural').ref.doc().id;
    // console.log(cotizacion.id);
    this.fs.addCotizacionPersonaNatural(this.cotizacion.id, this.formDatos.value)
    .then(() => {
      this.router.navigate([`/cotizar/personaNatural/${this.cotizacion.id}/gracias`]);
    }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  openModalTerminos() {
    const dialogRef = this.dialog.open(PoliticaPrivacidadModalComponent, {
      panelClass: 'myModalPrivacidad'
    });
    dialogRef.afterClosed().subscribe();
  }

  errorNombre() {
    return this.formDatos.controls.nombre.hasError('required') ? 'Ingrese su nombre completo' :
    this.formDatos.controls.nombre.hasError('pattern') ? 'Solo se admiten letras' : '';
  }

  errorEmail() {
    return this.formDatos.controls.email.hasError('required') ? 'El email es necesario' :
    this.formDatos.controls.email.hasError('email') ? 'Ingresa un email válido' : '';
  }

  errorCelular() {
    return this.formDatos.controls.celular.hasError('required') ? 'Ingrese un número de celular' :
    this.formDatos.controls.celular.hasError('pattern') ? 'Solo se admiten números.' :
    this.formDatos.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formDatos.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' : '';
  }

  errorAcepta() {
    return this.formDatos.controls.acepta.hasError('required') ? 'Es necesario aceptar este campo' : '';
  }

  errorTipoVehiculo() {
    return this.formDatos.controls.tipoVehiculo.hasError('required') ? 'Seleccione un tipo' : '';
  }

}
